import constructStore from './base';
import Vue from 'vue';

const listState = {
  filters: { 'me.deleted:eq': false },
  orfilters: {},
  sort: ['-me.id'],
  include: [
    'user_permissions.permission',
    'user_groups.group.group_permissions.permission'
  ],
  page: {
    number: 1,
    size: 20
  },
  count: 0,
  users: []
};

const listStore = constructStore(listState, 'users', 'users');

listStore.getters.getUsers = state => {
  state.users.forEach(user => {
    user.fullname = `${user.firstname} ${user.lastname}`;
    if (!user.updateFields) {
      Vue.set(user, 'updateFields', {
        username: user.username
      });
    }
    if (!user.permissions) {
      Vue.set(user, 'permissions', {});
      user.user_groups.forEach(userGroup => {
        userGroup.group.group_permissions.forEach(groupPermission => {
          if (user.permissions[groupPermission.permission.id]) {
            if (groupPermission.privilege == 'RW') {
              user.permissions[groupPermission.permission.id].groupPermission =
                groupPermission.privilege;
            }
          } else {
            Vue.set(user.permissions, groupPermission.permission.id, {
              permissionName: groupPermission.permission.name,
              groupPermission: groupPermission.privilege
            });
          }
        });
      });
      user.user_permissions.forEach(userPermission => {
        if (user.permissions[userPermission.permission_id]) {
          user.permissions[userPermission.permission_id].userPermission =
            userPermission.privilege;
          user.permissions[userPermission.permission_id].userPermissionId =
            userPermission.id;
        } else {
          Vue.set(user.permissions, userPermission.permission_id, {
            userPermissionId: userPermission.id,
            permissionName: userPermission.permission.name,
            userPermission: userPermission.privilege
          });
        }
      });
    }
  });
  return state.users;
};

const itemState = {
  include: [],
  user: {}
};

const itemStore = constructStore(itemState, 'user', 'users');

export default {
  list: listStore,
  item: itemStore
};
