<template>
  <span class="has-text-primary nx-hoveractionbar">
    <div class="nx-hoveractionbar__container">
      <slot name="items"></slot>
    </div>
  </span>
</template>

<script>
export default {
  name: 'NxHoverActionBar'
};
</script>

<style lang="scss">
.nx-hoveractionbar {
  position: absolute;
  display: flex;
  height: 100%;
  top: 0;
  right: 0;
  z-index: 20;
  background-color: #fafafa;

  &__container {
    display: flex;
    width: 100%;
    flex-direction: row;
    align-items: center;
    margin-right: 1rem;
  }

  &__item {
    cursor: pointer;
    margin-left: 1rem;
    &:hover {
      color: $darkblue;
    }
  }

  &__action-text {
    font-size: 0.5rem;
  }

  &__selected {
    background-color: $darkblue;
  }
}
</style>
