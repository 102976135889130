<template>
  <nav
    class="navbar is-light sub-nav-bar"
    role="navigation"
    aria-label="main navigation"
  >
    <div class="navbar-menu">
      <div class="subnavbar-menu">
        <router-link
          v-for="menuItem in menuItems"
          :key="menuItem.name"
          :v-if="$canRead('sms_mgt')"
          class="navbar-item"
          :to="menuItem.url"
          >{{ menuItem.name }}</router-link
        >
      </div>
    </div>
  </nav>
</template>

<script>
export default {
  name: 'SubNavBar',
  props: ['menuItems'],
  data() {
    return {};
  },
  computed: {
    profile() {
      return this.$store.state.me.info;
    }
  }
};
</script>

<style scoped lang="scss">
@media screen and (max-width: 1025px) {
  nav {
    visibility: hidden;
  }
}
</style>
