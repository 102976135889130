function getToken() {
  return window.localStorage.getItem('admin_token');
}

function setToken(token) {
  window.localStorage.setItem('admin_token', token);
}

function removeToken() {
  window.localStorage.removeItem('admin_token');
}

export { getToken, setToken, removeToken };
