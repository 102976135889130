<template>
  <b-input
    :value="value"
    :placeholder="placeholder"
    :icon="icon"
    :type="type"
    @input="setValue"
  ></b-input>
</template>
<script>
import debounce from 'lodash.debounce';

export default {
  name: 'NxDebouncedInput',
  props: ['value', 'placeholder', 'icon', 'type'],
  methods: {
    setValue: debounce(function (val) {
      this.$emit('input', val);
    }, 400)
  }
};
</script>
