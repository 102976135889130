import { get } from '@/common/api';
const internalClientsFilter = 4;

export default {
  namespaced: true,
  state: {
    _items: [],
    _query: {
      include: ['oracle_customer_mapping'],
      filters: {
        'me.clientid:eq:col': 'me.id',
        'me.clientid:gt': internalClientsFilter
      },
      sort: ['name']
    }
  },
  getters: {
    items: state => state._items,
    items_wo_mapping: state => airtimeClientId => {
      return state._items.filter(
        retailer =>
          retailer.oracle_customer_mapping == null ||
          (airtimeClientId != null && retailer.id == airtimeClientId)
      );
    }
  },
  mutations: {
    setList(state, data) {
      state._items = data;
    }
  },
  actions: {
    fetchList({ commit, state }) {
      get('retailers', state._query)
        .then(response => {
          commit('setList', response.data.data);
        })
        .catch(console.log);
    }
  }
};
