import constructStore from './base';

const listState = {
  filters: {},
  orfilters: {},
  sort: ['-me.id'],
  include: [
    'customer.customer_account_managers.account_manager',
    'balances',
    'pricelist',
    'callback_credential',
    'account_api_key_configurations'
  ],
  page: {
    number: 1,
    size: 20
  },
  count: 0,
  accounts: []
};

const listStore = constructStore(listState, 'accounts', 'accounts');

listStore.getters.getAccounts = state => {
  return state.accounts;
};

const itemState = {
  include: [],
  account: {}
};

const itemStore = constructStore(itemState, 'account', 'accounts');

export default {
  list: listStore,
  item: itemStore
};
