<template>
  <b-field :label="label" label-position="on-border" class="tw-toggle">
    <div class="content">
      <input
        v-model="selectedOption"
        type="radio"
        :name="name"
        :value="false"
        @change="onSelection"
      />
      <label class="toggle"><i class="mdi mdi-close"></i></label>
      <input
        v-model="selectedOption"
        type="radio"
        :name="name"
        :value="null"
        @change="onSelection"
      />
      <label class="toggle"><i class="mdi mdi-minus"></i></label>
      <input
        v-model="selectedOption"
        type="radio"
        :name="name"
        :value="true"
        @change="onSelection"
      />
      <label class="toggle"><i class="mdi mdi-check"></i></label>
      <span></span>
    </div>
  </b-field>
</template>

<script>
export default {
  name: 'NxToggle',
  props: ['value', 'label', 'name'],
  data() {
    return {
      selectedOption: this.value
    };
  },
  methods: {
    onSelection() {
      this.$emit('input', this.selectedOption);
    }
  }
};
</script>

<style scoped lang="scss">
.tw-toggle {
  /* background: #95A5A6; */
  display: inline-block;
  position: relative;
  height: 2.5rem;
  margin-right: 1rem;
}

.tw-toggle label {
  text-align: center;
  font-family: sans-serif;
  display: inline-block;
  color: #95a5a6;
  position: relative;
  z-index: 2;
  margin: 0;
  text-align: center;
  padding: 2px 3px;
  font-size: 15px;
  /* cursor: pointer; */
}

.tw-toggle input {
  /* display: none; */
  position: absolute;
  z-index: 3;
  opacity: 0;
  height: 90%;
  min-width: 2rem;
  cursor: pointer;
  appearance: none;
  outline: none;

  &:hover {
    opacity: 1;
    border-bottom: 4px solid $secondary;
    transition: all 0.1s ease-in-out;
  }
}

.tw-toggle span {
  height: 18px;
  width: 18px;
  line-height: 18px;
  border-radius: 50%;
  background: #fff;
  display: block;
  position: absolute;
  left: 22px;
  top: 5px;
  transition: all 0.3s ease-in-out;
}

.tw-toggle input[value='false']:checked ~ span {
  background: #e74c3c;
  left: 4px;
  color: #fff;
}

.tw-toggle input[value='true']:checked ~ span {
  background: #27ae60;
  left: 46px;
}
.tw-toggle input[value='null']:checked ~ span {
  background: #95a5a6;
  left: 23px;
}

.tw-toggle input[value='false']:checked + label,
.tw-toggle input[value='true']:checked + label {
  color: #fff;
}
.tw-toggle input[value='null']:checked + label {
  color: #fff;
}

.content {
  border: 1px solid lightgray;
  border-radius: 5px;
  padding-right: 2px;
  padding-left: 2px;
}
</style>
