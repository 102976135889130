<template>
  <section v-if="$canRead('notifications')" style="display: flex">
    <div class="navbar-link" @click="setNotifiedAt">
      <b-icon icon="bell" type="is-dark"></b-icon>
    </div>
    <div class="navbar-dropdown is-scrollable" style="min-width: 19rem">
      <a
        v-for="notification of notifications"
        :key="notification.id"
        class="navbar-item"
        style="white-space: normal; padding-right: 1rem"
        href="/#/notifications"
      >
        {{ notification.content }}
      </a>
      <span v-if="!notifications.length" class="navbar-item">
        No notification
      </span>
    </div>
  </section>
</template>

<script>
import { get, put } from '@/common/api';

export default {
  name: 'Notification',
  data() {
    return {
      notifications: [],
      lastNotifiedAt: null
    };
  },
  computed: {
    profile() {
      return this.$store.state.me.info;
    }
  },
  watch: {
    profile: function () {
      if (this.profile.id) {
        this.fetchNotifications(this.profile);
      }
    }
  },
  created() {
    this.profile;
  },
  methods: {
    setNotifiedAt() {
      if (this.lastNotifiedAt) {
        this.notifications = [];
      }
      if (this.profile.id) {
        this.fetchNotifications(this.profile);
        put(`me`, {
          last_notified_at: new Date().toISOString()
        }).then(response => {
          this.lastNotifiedAt = new Date().toISOString();
        });
      }
    },
    fetchNotifications(profile) {
      let notifiedAt = this.lastNotifiedAt
        ? this.lastNotifiedAt
        : profile.last_notified_at;
      get('notifications', {
        include: [
          'group_notification_configuration.group.user_groups',
          'user_notification_configuration'
        ],
        filters: {
          'me.created_at:gt': notifiedAt,
          'me.user_notification_configuration_id:eq': 'null',
          'user_groups.user_id:eq': profile.id
        },
        orfilters: {
          'me.created_at:gt': notifiedAt,
          'user_notification_configuration.user_id:eq': profile.id,
          'me.group_notification_configuration_id:eq': 'null'
        },
        sort: ['-me.id']
      })
        .then(response => {
          this.notifications = response.data.data;
        })
        .catch(error => {
          console.log(error);
        });
    }
  }
};
</script>

<style lang="scss">
.is-scrollable {
  max-height: 200px;
  overflow-y: auto;
}
</style>
