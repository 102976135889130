import { getToken, removeToken } from './auth';
import axios from 'axios';
import { isEmpty } from 'ramda';
import { SnackbarProgrammatic as Toast } from 'buefy';

const base = process.env.VUE_APP_API;

function truncate(input) {
  if (input.length > 200) {
    return input.substring(0, 200) + '...';
  }
  return input;
}

axios.defaults.baseURL = process.env.VUE_APP_API;

axios.interceptors.request.use(
  config => {
    config.headers.Authorization = `Bearer ${getToken()}`;
    return config;
  },
  error => {
    return Promise.reject(error);
  }
);

axios.interceptors.response.use(
  response => {
    return response;
  },
  error => {
    if (error.response.status === 401) {
      localStorage.setItem(
        'post_login_redirect',
        window.location.hash.substr(1)
      );
      removeToken();
      window.location.replace(
        `${process.env.VUE_APP_API}/auth/login?redirect=${window.location.href}`
      );
    } else if (error.response.status === 400) {
      Toast.open({
        indefinite: true,
        message: `SERVER ERROR: ${truncate(
          error.response.data.message
        )} </br> Request Id: ${error.response.data.request_id}`,
        position: 'is-top',
        type: 'is-danger'
      });
    } else if (error.response.status === 500) {
      Toast.open({
        indefinite: true,
        message: `ERROR: ${truncate(error.response.data.exception)}`,
        position: 'is-top',
        type: 'is-danger'
      });
    }
    return Promise.reject(error);
  }
);

function getRequest(path, method, data) {
  const headers = {};

  let req = {};

  if (data) {
    headers['Content-Type'] = 'application/json';
    req = {
      url: `${base}/${path}`,
      method,
      headers,
      data
    };
  } else {
    req = {
      url: `${base}/${path}`,
      method,
      headers
    };
  }
  return req;
}

function isFalsy(value) {
  return (
    value === null || value === undefined || value === '' || isEmpty(value)
  );
}

function formatFilters(filterObj, prefix) {
  const filter = {};
  Object.keys(filterObj).forEach(key => {
    if (!isFalsy(filterObj[key])) {
      filter[`${prefix}[${key}]`] = filterObj[key];
    }
  });
  return filter;
}

function constructQuery(params) {
  if (!params) {
    return;
  }
  const query = []; // eslint-disable-line

  if (params.filters) {
    query.push(
      new URLSearchParams(formatFilters(params.filters, 'where')).toString()
    );
  }

  if (params.orfilters) {
    query.push(
      new URLSearchParams(formatFilters(params.orfilters, 'orwhere')).toString()
    );
  }

  if (params.include) {
    query.push(
      new URLSearchParams({
        include: `${params.include.join(',')}`
      }).toString()
    );
  }

  if (params.page) {
    if (params.page.number) {
      query.push(`page[number]=${params.page.number}`);
    }

    if (params.page.size) {
      query.push(`page[size]=${params.page.size}`);
    }
  }

  if (params.sort) {
    query.push(
      new URLSearchParams({
        sort: `${params.sort.join(',')}`
      }).toString()
    );
  }

  if (params.distinct) {
    if (params.distinct) {
      query.push(`distinct=${params.distinct}`);
    }
  }

  if (params.fields) {
    query.push(
      new URLSearchParams({
        fields: `${params.fields.join(',')}`
      }).toString()
    );
  }

  return query.join('&');
}

function get(path, params) {
  const query = constructQuery(params);
  const req = query ? `${path}?${constructQuery(params)}` : path;
  return axios(getRequest(req, 'GET'));
}

function post(path, data) {
  return axios(getRequest(path, 'POST', data));
}

function bulkUpdate(path, params, data) {
  const paramPath = `${path}?${constructQuery(params)}`;
  return axios(getRequest(paramPath, 'PUT', data));
}

function put(path, data) {
  return axios(getRequest(path, 'PUT', data));
}

function del(path, data) {
  return axios(getRequest(path, 'DELETE', data));
}

function bulkDelete(path, params, data) {
  const paramPath = `${path}?${constructQuery(params)}`;
  return axios(getRequest(paramPath, 'DELETE', data));
}

function upload(request) {
  request['url'] = `${base}/${request['url']}`;
  return axios(request);
}

export { get, post, put, del, bulkUpdate, bulkDelete, upload };
