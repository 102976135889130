import constructStore from './base';
import Vue from 'vue';

const listState = {
  filters: {},
  orfilters: {},
  sort: ['-me.id', 'permission.name', 'user.username'],
  include: ['group_permissions.permission', 'user_groups.user'],
  page: {
    number: 1,
    size: 20
  },
  count: 0,
  groups: []
};

const listStore = constructStore(listState, 'groups', 'groups');

listStore.getters.getGroups = state => {
  state.groups.forEach(group => {
    group.user_groups = group.user_groups.filter(i => !i.user.deleted);
    if (!group.updateFields) {
      Vue.set(group, 'updateFields', {
        name: group.name
      });
    }
  });
  return state.groups;
};

const itemState = {
  include: [],
  group: {}
};

const itemStore = constructStore(itemState, 'group', 'groups');

export default {
  list: listStore,
  item: itemStore
};
