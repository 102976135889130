import Vue from 'vue';
import Buefy from 'buefy';
import Vuelidate from 'vuelidate';
import App from './App.vue';
import router from './router';
import store from './store/index';
import '@/common/filters';
import '@/common/directives';
import '@/components';

Vue.config.productionTip = false;
Vue.use(Buefy, {
  defaultIconPack: 'mdi'
});
Vue.use(Vuelidate);

Vue.prototype.$timeZone = process.env.VUE_APP_TIMEZONE;

Vue.prototype.$canWrite = function (resource) {
  return (
    store.state.me.info.permissions &&
    (store.state.me.info.permissions[resource] === 'RW' ||
      store.state.me.info.permissions.admin)
  );
};

Vue.prototype.$timeZone = process.env.VUE_APP_TIMEZONE;

Vue.prototype.$canRead = function (resource) {
  return (
    store.state.me.info.permissions &&
    (store.state.me.info.permissions[resource] ||
      store.state.me.info.permissions.admin)
  );
};

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app');
