import { get } from '@/common/api';

const BLACKLIST = ['Antarctic'];

export default {
  namespaced: true,
  state: {
    _items: [],
    _query: {
      sort: ['region']
    }
  },
  getters: {
    items: state => state._items
  },
  mutations: {
    setList(state, data) {
      state._items = data.filter(i => !BLACKLIST.includes(i.region));
    }
  },
  actions: {
    fetchList({ commit, state }) {
      get('regions', state._query)
        .then(response => {
          commit('setList', response.data.data);
        })
        .catch(console.log);
    }
  }
};
