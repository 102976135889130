<template>
  <b-autocomplete
    ref="autocomplete"
    v-model="searchText"
    class="nx-autocomplete"
    :placeholder="placeholder"
    :open-on-focus="true"
    :data="itemSearchResult"
    :field="field"
    :keep-first="true"
    :loading="loading"
    :clearable="clearable"
    @select="onSelect"
  >
  </b-autocomplete>
</template>

<script>
import { scrollUp } from '@/common/helpers';

export default {
  name: 'NxSimpleAutocomplete',
  props: {
    clearable: {
      type: Boolean,
      default: false
    },
    field: {
      type: String,
      default: 'name'
    },
    items: {
      type: Array,
      default: () => []
    },
    placeholder: {
      type: String,
      default: ''
    },
    value: {},
    returnObject: {
      type: Boolean,
      default: false
    },
    selectionKey: {
      type: String,
      default: 'id'
    },
    loading: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      searchText: '',
      selected: null
    };
  },
  computed: {
    itemSearchResult() {
      if (!this.searchText) {
        return this.items;
      }
      try {
        scrollUp(this.$el);
      } catch (error) {
        // TODO: ADD_TO_LOG
        console.log(error);
      }
      return this.items.filter(option => {
        return (
          option[this.field] &&
          option[this.field]
            .toString()
            .toLowerCase()
            .indexOf(this.searchText.toLowerCase()) >= 0
        );
      });
    }
  },
  watch: {
    value() {
      this.$refs.autocomplete.setSelected(
        this.items.find(item => item[this.selectionKey] == this.value)
      );
    },
    items() {
      this.$refs.autocomplete.setSelected(
        this.items.find(item => item[this.selectionKey] == this.value)
      );
    }
  },
  created() {
    if (this.value) {
      this.selected = this.items.find(
        item => item[this.selectionKey] == this.value
      );
    }
  },
  methods: {
    onSelect(item) {
      this.selected = item;
      this.$emit(
        'input',
        this.returnObject ? item || {} : item && item[this.selectionKey]
      );
      this.$emit('select', item);
    }
  }
};
</script>

<style lang="scss">
.nx-autocomplete {
  width: 14rem;
  & > .dropdown-menu > .dropdown-content > .dropdown-item {
    white-space: normal;
    padding-right: 1rem;

    &:hover {
      color: white;
      background-color: $primary;
    }
  }
}
</style>
