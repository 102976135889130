<template>
  <b-autocomplete
    v-if="!visible"
    ref="autocomplete"
    v-model="searchText"
    class="nx-autocomplete"
    :placeholder="placeholder"
    :open-on-focus="true"
    :data="itemSearchResult"
    :field="field"
    :clearable="clearable"
    :keep-first="true"
    :autocomplete="autofill ? '' : 'none'"
    @select="
      item => {
        selected = item;
        $emit('input', item && item[selectedKey]);
        $emit('item-selection', item);
      }
    "
    @blur="$emit('getSearchText', searchText)"
  >
    <template slot-scope="props">
      <div>
        {{ accessNestedObj(path, props.option) }}
      </div>
    </template>
  </b-autocomplete>
</template>

<script>
import { get } from '@/common/api';
import { scrollUp } from '@/common/helpers';
import { path } from 'ramda';

export default {
  name: 'NxAutocomplete',
  props: [
    'resource',
    'field',
    'params',
    'placeholder',
    'dataFilter',
    'value',
    'customKey',
    'autofill',
    'passedData',
    'searchResult',
    'clearable',
    'visible'
  ],
  data() {
    return {
      searchText: '',
      items: [],
      selected: null,
      searchbox: []
    };
  },
  computed: {
    path() {
      return this.field.split('.');
    },
    itemSearchResult() {
      if (!this.searchText) {
        return this.items;
      }
      try {
        scrollUp(this.$el);
      } catch (error) {
        // TODO: ADD_TO_LOG
        console.log(error);
      }

      if (this.searchResult) {
        return this.searchResult(this.items, this.searchText);
      }

      return this.items.filter(option => {
        return (
          option[this.field]
            .toString()
            .toLowerCase()
            .indexOf(this.searchText.toLowerCase()) >= 0
        );
      });
    },
    selectedKey() {
      return this.customKey ? this.customKey : 'id';
    }
  },
  created() {
    if (!this.passedData) {
      get(this.resource, this.params).then(response => {
        this.setData(response.data.data);
      });
    } else {
      this.setData(this.passedData);
    }
  },
  methods: {
    accessNestedObj(arrPath, obj) {
      return path(arrPath, obj);
    },
    setData(list) {
      if (this.dataFilter) {
        this.items = this.dataFilter(list);
      } else {
        this.items = list;
      }
      if (this.value) {
        this.$refs.autocomplete.setSelected(
          this.items.find(item => item[this.selectedKey] == this.value)
        );
      }
    }
  }
};
</script>

<style lang="scss">
.nx-autocomplete {
  width: 14rem;
  & > .dropdown-menu > .dropdown-content > .dropdown-item {
    white-space: normal;
    padding-right: 1rem;

    &:hover {
      color: white;
      background-color: $primary;
    }
  }
}

.nx-autocomplete-break-word .dropdown-item {
  overflow-wrap: break-word;
}
</style>
