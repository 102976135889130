import Vue from 'vue';
import Router from 'vue-router';
import { getToken } from './common/auth';
import store from './store/index';
import qs from 'qs';

Vue.use(Router);

const router = new Router({
  routes: [
    {
      path: '/',
      redirect: localStorage.preferences
        ? JSON.parse(localStorage.getItem('preferences')).homepage
        : '/products'
    },
    {
      path: '*',
      name: 'notFound',
      component: () => import('./views/error/404.vue'),
      meta: {
        title: 'Not Found'
      }
    },
    {
      path: '/403',
      name: 'forbidden',
      component: () => import('./views/error/403.vue'),
      meta: {
        title: 'Forbidden'
      }
    },
    {
      path: '/accounts',
      component: () => import('./views/account/Index.vue'),
      children: [
        {
          path: '',
          name: 'accountList',
          component: () => import('./views/account/List.vue'),
          meta: {
            requiresPermissions: ['accounts', 'RO'],
            title: 'Accounts'
          }
        },
        {
          path: 'create',
          name: 'accountCreate',
          component: () => import('./views/account/Create.vue'),
          meta: {
            requiresPermissions: ['accounts', 'RW']
          }
        }
      ]
    },
    {
      path: '/balance_management',
      component: () => import('./views/balance_management/Index.vue'),
      children: [
        {
          path: '',
          name: 'balanceMovementList',
          component: () => import('./views/balance_management/List.vue'),
          meta: {
            requiresPermissions: ['balance_movements', 'RO'],
            title: 'Balance Management'
          }
        },
        {
          path: 'create',
          name: 'balanceMovementCreate',
          component: () => import('./views/balance_management/Create.vue'),
          meta: {
            requiresPermissions: ['balance_movements', 'RW'],
            title: 'Update Balance'
          }
        }
      ]
    },
    {
      path: '/customers',
      component: () => import('./views/customer/Index.vue'),
      children: [
        {
          path: '',
          name: 'customerList',
          component: () => import('./views/customer/List.vue'),
          meta: {
            requiresPermissions: ['customers', 'RO'],
            title: 'Customers'
          }
        },
        {
          path: 'create',
          name: 'customerCreate',
          component: () => import('./views/customer/Create.vue'),
          meta: {
            requiresPermissions: ['customers', 'RW'],
            title: 'Create Customer'
          }
        }
      ]
    },
    {
      path: '/groups',
      component: () => import('./views/group/Index.vue'),
      children: [
        {
          path: '',
          name: 'groupList',
          component: () => import('./views/group/List.vue'),
          meta: {
            requiresPermissions: ['groups', 'RO'],
            title: 'Groups'
          }
        },
        {
          path: 'create',
          name: 'groupCreate',
          component: () => import('./views/group/Create.vue'),
          meta: {
            requiresPermissions: ['groups', 'RW'],
            title: 'Create Group'
          }
        }
      ]
    },
    {
      path: '/permissions',
      component: () => import('./views/permission/Index.vue'),
      children: [
        {
          path: '',
          name: 'permissionList',
          component: () => import('./views/permission/List.vue'),
          meta: {
            requiresPermissions: ['permissions', 'RO'],
            title: 'Permissions'
          }
        },
        {
          path: 'create',
          name: 'permissionCreate',
          component: () => import('./views/permission/Create.vue'),
          meta: {
            requiresPermissions: ['permissions', 'RW'],
            title: 'Create Permission'
          }
        }
      ]
    },
    {
      path: '/product_segments',
      component: () => import('./views/product_segment/Index.vue'),
      children: [
        {
          path: '',
          name: 'productSegmentList',
          component: () => import('./views/product_segment/List.vue'),
          meta: {
            title: 'Product Segments'
          }
        },
        {
          path: 'create',
          name: 'productSegmentCreate',
          component: () => import('./views/product_segment/Create.vue'),
          meta: {
            requiresPermissions: ['products', 'RW'],
            title: 'Create Product Segments'
          }
        }
      ]
    },
    {
      path: '/products',
      component: () => import('./views/product/Index.vue'),
      children: [
        {
          path: '',
          name: 'productList',
          component: () => import('./views/product/List.vue'),
          meta: {
            requiresPermissions: ['products', 'RO'],
            title: 'Products'
          }
        },
        {
          path: 'create',
          name: 'productCreate',
          component: () => import('./views/product/bulk_products/Create.vue'),
          meta: {
            requiresPermissions: ['products', 'RW'],
            title: 'Create Product'
          }
        },
        {
          path: 'history',
          name: 'productHistory',
          component: () => import('./views/product/History.vue'),
          meta: {
            requiresPermissions: ['products', 'RO'],
            title: 'Products'
          }
        },
        {
          path: 'help',
          name: 'productHelp',
          component: () => import('./views/product/Help.vue'),
          meta: {
            requiresPermissions: ['products', 'RO'],
            title: 'Product Help'
          }
        },
        {
          path: 'export',
          name: 'productExport',
          component: () => import('./views/product/Export.vue'),
          meta: {
            requiresPermissions: ['products', 'RO'],
            title: 'Export Products'
          }
        }
      ]
    },
    {
      path: '/modules',
      component: () => import('./views/module/Index.vue'),
      children: [
        {
          path: '',
          name: 'moduleList',
          component: () => import('./views/module/List.vue'),
          meta: {
            requiresPermissions: ['modules', 'RO'],
            title: 'Modules'
          }
        },
        {
          path: 'create',
          name: 'moduleCreate',
          component: () => import('./views/module/Create.vue'),
          meta: {
            requiresPermissions: ['modules', 'RW'],
            title: 'Create Module'
          }
        }
      ]
    },
    {
      path: '/pricelists',
      component: () => import('./views/pricelist/Index.vue'),
      children: [
        {
          path: '',
          name: 'pricelistList',
          component: () => import('./views/pricelist/List.vue'),
          meta: {
            requiresPermissions: ['pricelists', 'RO'],
            title: 'Pricelists'
          }
        },
        {
          path: 'create',
          name: 'pricelistCreate',
          component: () => import('./views/pricelist/Create.vue'),
          meta: {
            requiresPermissions: ['pricelists', 'RW'],
            title: 'Create Pricelist'
          }
        },
        {
          path: 'refresh',
          name: 'specificPricelistItemRefresh',
          component: () =>
            import('./views/pricelist/pricelist_item/Refresh.vue'),
          props: true,
          meta: {
            requiresPermissions: ['pricelists', 'RW'],
            title: 'Refresh Pricelist Items'
          }
        },
        {
          path: 'pricelist_items/create',
          name: 'pricelistItemCreate',
          component: () =>
            import('./views/pricelist/pricelist_item/Create.vue'),
          props: true,
          meta: {
            requiresPermissions: ['pricelists', 'RW'],
            title: 'Add to Pricelists'
          }
        },
        {
          path: ':pricelist_id/pricelist_items/create',
          component: () => import('./views/pricelist/pricelist_item/Index.vue'),
          children: [
            {
              path: 'create',
              name: 'specificPricelistItemCreate',
              component: () =>
                import('./views/pricelist/pricelist_item/Create.vue'),
              props: true,
              meta: {
                requiresPermissions: ['pricelists', 'RW'],
                title: 'Add to Pricelist'
              }
            }
          ]
        }
      ]
    },
    {
      path: '/pricelists/:pricelist_id/pricelist_items',
      component: () => import('./views/pricelist/pricelist_item/Index.vue'),
      children: [
        {
          path: '',
          name: 'pricelistItemList',
          component: () => import('./views/pricelist/pricelist_item/List.vue'),
          meta: {
            title: 'Prices'
          }
        }
      ]
    },
    {
      path: '/pricelists/:pricelist_id/pricelist_versions/:version_id',
      component: () => import('./views/pricelist/pricelist_version/Index.vue'),
      children: [
        {
          path: '',
          name: 'pricelistVersionList',
          component: () =>
            import('./views/pricelist/pricelist_version/List.vue'),
          meta: {
            title: 'Price History'
          }
        }
      ]
    },
    {
      path: '/pricelist_templates',
      component: () => import('./views/pricelist_template/Index.vue'),
      children: [
        {
          path: '',
          name: 'pricelistTemplateList',
          component: () => import('./views/pricelist_template/List.vue'),
          meta: {
            requiresPermissions: ['pricelists', 'RO'],
            title: 'Pricelist Templates'
          }
        },
        {
          path: 'create',
          name: 'pricelistTemplateCreate',
          component: () => import('./views/pricelist_template/Create.vue'),
          meta: {
            requiresPermissions: ['pricelists', 'RW'],
            title: 'Create Pricelist Templates'
          }
        },
        {
          path: ':template_id/pricelist_template_rules',
          component: () => import('./views/pricelist_template/rule/Index.vue'),
          children: [
            {
              path: '',
              name: 'pricelistTemplateRuleList',
              component: () =>
                import('./views/pricelist_template/rule/List.vue'),
              meta: {
                requiresPermissions: ['pricelists', 'RO'],
                title: 'Pricing Template Rules'
              }
            }
          ]
        }
      ]
    },
    {
      path: '/communicate_pricelist_changes',
      component: () =>
        import('./views/communicate_pricelist_changes/Index.vue'),
      children: [
        {
          path: '',
          name: 'commPricelistList',
          component: () =>
            import('./views/communicate_pricelist_changes/List.vue'),
          meta: {
            requiresPermissions: ['pricelists', 'RO'],
            title: 'Communicate Pricelist Changes'
          }
        },
        {
          path: 'form/pricelist/:pricelist_id/pricelist_versions/:pricelist_version_id',
          name: 'commPricelistForm',
          component: () =>
            import('./views/communicate_pricelist_changes/Form.vue'),
          meta: {
            requiresPermissions: ['pricelists', 'RW'],
            title: 'Communicate Pricelist Form'
          }
        }
      ]
    },
    {
      path: '/operators',
      component: () => import('./views/operator/Index.vue'),
      children: [
        {
          path: '',
          name: 'operatorList',
          component: () => import('./views/operator/List.vue'),
          meta: {
            requiresPermissions: ['operators', 'RO'],
            title: 'Operators'
          }
        },
        {
          path: 'create',
          name: 'OperatorCreate',
          component: () => import('./views/operator/Create.vue'),
          meta: {
            requiresPermissions: ['operators', 'RW'],
            title: 'Create Operator'
          }
        }
      ]
    },
    {
      path: '/operator_codes',
      component: () => import('./views/operator_codes/Index.vue'),
      children: [
        {
          path: '',
          name: 'OperatorCodesList',
          component: () => import('./views/operator_codes/List.vue'),
          meta: {
            requiresPermissions: ['operators', 'RO'],
            title: 'Operators Codes'
          }
        },
        {
          path: 'create',
          name: 'OperatorCodesCreate',
          component: () => import('./views/operator_codes/Create.vue'),
          meta: {
            requiresPermissions: ['operators', 'RW'],
            title: 'Create Operator Code'
          }
        },
        {
          path: 'import',
          name: 'OperatorCodesImport',
          component: () => import('./views/operator_codes/Import.vue'),
          meta: {
            requiresPermissions: ['operators', 'RW'],
            title: 'Import MCC/MNC operators codes'
          }
        }
      ]
    },
    {
      path: '/providers',
      component: () => import('./views/provider/Index.vue'),
      children: [
        {
          path: '',
          name: 'providerList',
          component: () => import('./views/provider/List.vue'),
          meta: {
            requiresPermissions: ['providers', 'RO'],
            title: 'Providers'
          }
        },
        {
          path: 'create',
          name: 'ProviderCreate',
          component: () => import('./views/provider/Create.vue'),
          meta: {
            requiresPermissions: ['providers', 'RW'],
            title: 'Create Provider'
          }
        }
      ]
    },
    {
      path: '/costs',
      component: () => import('./views/cost/Index.vue'),
      children: [
        {
          path: '',
          name: 'costList',
          component: () => import('./views/cost/List.vue'),
          meta: {
            requiresPermissions: ['costing', 'RO'],
            title: 'Costs'
          }
        }
      ]
    },
    {
      path: '/costing_rules',
      component: () => import('./views/cost/rule/Index.vue'),
      children: [
        {
          path: '',
          name: 'costingRuleList',
          component: () => import('./views/cost/rule/List.vue'),
          meta: {
            requiresPermissions: ['costing', 'RO'],
            title: 'Costing Rules'
          }
        },
        {
          path: 'create',
          name: 'costingRuleCreate',
          component: () => import('./views/cost/rule/Create.vue'),
          meta: {
            requiresPermissions: ['costing', 'RW'],
            title: 'Create Costing Rule'
          }
        }
      ]
    },
    {
      path: '/provider_wallet_transfers',
      component: () =>
        import('./views/provider/provider_wallet_transfer/Index.vue'),
      children: [
        {
          path: '',
          name: 'providerWalletTransferList',
          component: () =>
            import('./views/provider/provider_wallet_transfer/List.vue'),
          meta: {
            requiresPermissions: ['providers', 'RO'],
            title: 'Provider Wallet Management'
          }
        },
        {
          path: 'create',
          name: 'providerWalletTransferCreate',
          component: () =>
            import('./views/provider/provider_wallet_transfer/Create.vue'),
          meta: {
            requiresPermissions: ['providers', 'RW'],
            title: 'Update provider balance'
          }
        }
      ]
    },
    {
      path: '/countries',
      component: () => import('./views/country/Index.vue'),
      children: [
        {
          path: '',
          name: 'countryList',
          component: () => import('./views/country/List.vue'),
          meta: {
            requiresPermissions: ['countries', 'RO'],
            title: 'Countries'
          }
        },
        {
          path: 'create',
          name: 'countryCreate',
          component: () => import('./views/country/Create.vue'),
          meta: {
            requiresPermissions: ['countries', 'RW'],
            title: 'Create Country'
          }
        }
      ]
    },
    {
      path: '/countries_banks',
      component: () => import('./views/country_bank/Index.vue'),
      children: [
        {
          path: '',
          name: 'countryBankList',
          component: () => import('./views/country_bank/List.vue'),
          meta: {
            requiresPermissions: ['countries', 'RO'],
            title: 'Countries and Banks'
          }
        },
        {
          path: 'create',
          name: 'countryBankCreate',
          component: () => import('./views/country_bank/Create.vue'),
          meta: {
            requiresPermissions: ['countries', 'RW'],
            title: 'Create a Country Bank Mapping'
          }
        }
      ]
    },
    {
      path: '/currencies',
      component: () => import('./views/currency/Index.vue'),
      children: [
        {
          path: '',
          name: 'currencyList',
          component: () => import('./views/currency/List.vue'),
          meta: {
            requiresPermissions: ['currencies', 'RO'],
            title: 'Currencies'
          }
        },
        {
          path: 'create',
          name: 'currencyCreate',
          component: () => import('./views/currency/Create.vue'),
          meta: {
            requiresPermissions: ['currencies', 'RW'],
            title: 'Create Currency'
          }
        },
        {
          path: 'currencyHistory',
          name: 'currencyHistory',
          component: () => import('./views/currency/History.vue'),
          meta: {
            requiresPermissions: ['currencies', 'RO'],
            title: 'Currency History'
          }
        }
      ]
    },
    {
      path: '/currency_rate_transfer',
      component: () => import('./views/currency_rate_transfer/Index.vue'),
      children: [
        {
          path: '',
          name: 'currencyTransferList',
          component: () => import('./views/currency_rate_transfer/List.vue'),
          meta: {
            requiresPermissions: ['currencies', 'RO'],
            title: 'Currencies Rate Transfer'
          }
        }
      ]
    },
    {
      path: '/currency_rate_transfer_operators_providers',
      component: () =>
        import('./views/currency_rate_transfer_operators_providers/Index.vue'),
      children: [
        {
          path: '',
          name: 'currencyTransferOperatorsProvidersList',
          component: () =>
            import(
              './views/currency_rate_transfer_operators_providers/List.vue'
            ),
          meta: {
            requiresPermissions: ['currencies', 'RO'],
            title: 'Currencies Rate Transfer Operators Providers'
          }
        }
      ]
    },
    {
      path: '/promotions',
      component: () => import('./views/promotion/Index.vue'),
      children: [
        {
          path: '',
          name: 'promotionList',
          component: () => import('./views/promotion/List.vue'),
          meta: {
            requiresPermissions: ['promotions', 'RO'],
            title: 'Promotions'
          }
        },
        {
          path: 'create',
          name: 'promotionCreate',
          component: () => import('./views/promotion/Create.vue'),
          meta: {
            requiresPermissions: ['promotions', 'RW'],
            title: 'Create Promotion'
          }
        }
      ]
    },
    {
      path: '/routes',
      component: () => import('./views/route/Index.vue'),
      children: [
        {
          path: '',
          name: 'routeList',
          component: () => import('./views/route/List.vue'),
          meta: {
            requiresPermissions: ['routes', 'RO'],
            title: 'Routes'
          }
        },
        {
          path: 'create',
          name: 'routeCreate',
          component: () => import('./views/route/Create.vue'),
          meta: {
            requiresPermissions: ['routes', 'RW'],
            title: 'Create Routes'
          }
        },
        {
          path: 'history',
          name: 'routeHistory',
          component: () => import('./views/route/History.vue'),
          meta: {
            requiresPermissions: ['routes', 'RO'],
            title: 'Routes History'
          }
        }
      ]
    },
    {
      path: '/transactions',
      component: () => import('./views/transaction/Index.vue'),
      children: [
        {
          path: '',
          name: 'transactionList',
          component: () => import('./views/transaction/List.vue'),
          meta: {
            requiresPermissions: ['transactions', 'RO'],
            title: 'Transactions'
          }
        },
        {
          path: 'bulk_update',
          name: 'transactionBulkUpdate',
          component: () => import('./views/transaction/BulkUpdate.vue'),
          meta: {
            requiresPermissions: ['transactions', 'RW'],
            title: 'Bulk Update Transactions'
          }
        }
      ]
    },
    {
      path: '/finance',
      component: () => import('./views/finance/Index.vue'),
      children: [
        {
          path: 'gross_margin_adjustments/billing',
          name: 'gross_margin_adjustments/billing',
          component: () =>
            import('./views/finance/gross_margin_adjustments/billing/List.vue'),
          meta: {
            requiresPermissions: ['adjustment', 'RW'],
            title: 'Gross Margin Adjustments - Billing'
          }
        },
        {
          path: 'gross_margin_adjustments/billing/create',
          name: 'GrossMarginBillingCreate',
          component: () =>
            import(
              './views/finance/gross_margin_adjustments/billing/Create.vue'
            ),
          meta: {
            requiresPermissions: ['adjustment', 'RW']
          }
        },
        {
          path: 'gross_margin_adjustments/billing/upload',
          name: 'GrossMarginBillingUpload',
          component: () =>
            import(
              './views/finance/gross_margin_adjustments/billing/Upload.vue'
            ),
          meta: {
            requiresPermissions: ['adjustment', 'RW']
          }
        }
      ]
    },
    {
      path: '/users',
      component: () => import('./views/user/Index.vue'),
      children: [
        {
          path: '',
          name: 'userList',
          component: () => import('./views/user/List.vue'),
          meta: {
            requiresPermissions: ['users', 'RW'],
            title: 'Users'
          }
        }
      ]
    },
    {
      path: '/logins',
      component: () => import('./views/login/Index.vue'),
      children: [
        {
          path: '',
          name: 'loginList',
          component: () => import('./views/login/List.vue'),
          meta: {
            requiresPermissions: ['users', 'RW'],
            title: 'Logins'
          }
        },
        {
          path: 'create',
          name: 'loginCreate',
          component: () => import('./views/login/Create.vue'),
          meta: {
            requiresPermissions: ['users', 'RW']
          }
        }
      ]
    },
    {
      path: '/dashboard',
      component: () => import('./views/dashboard/Dashboard.vue'),
      meta: {
        title: 'Dashboard'
      }
    },
    {
      path: '/sso/callback',
      name: 'callback',
      component: () => import('./views/sso/Callback.vue'),
      meta: {
        requiresPermissions: false
      }
    },
    {
      path: '/rates/fx-rates',
      component: () => import('./views/fx_fixed_rates/Index.vue'),
      children: [
        {
          path: '',
          name: 'FxRates',
          component: () => import('./views/fx_rates/List.vue'),
          meta: {
            requiresPermissions: ['rates', 'RO'],
            title: 'Fx Rates'
          }
        }
      ]
    },
    {
      path: '/rates/fx-fixed-rates',
      component: () => import('./views/fx_fixed_rates/Index.vue'),
      children: [
        {
          path: '',
          name: 'FixedRatesList',
          component: () => import('./views/fx_fixed_rates/List.vue'),
          meta: {
            requiresPermissions: ['rates', 'RW'],
            title: 'FX Fixed Rates'
          }
        },
        {
          path: 'create',
          name: 'FixedRateCreate',
          component: () => import('./views/fx_fixed_rates/Create.vue'),
          meta: {
            requiresPermissions: ['rates', 'RW'],
            title: 'Create FX Fixed Rate'
          }
        }
      ]
    },
    {
      path: '/rates/fx-sources',
      component: () => import('./views/fx_sources/Index.vue'),
      children: [
        {
          path: '',
          name: 'FxSourcesList',
          component: () => import('./views/fx_sources/List.vue'),
          meta: {
            requiresPermissions: ['rates', 'RW'],
            title: 'Fx Sources'
          }
        },
        {
          path: 'create',
          name: 'FxSourceCreate',
          component: () => import('./views/fx_sources/Create.vue'),
          meta: {
            requiresPermissions: ['rates', 'RW'],
            title: 'Create FX Source'
          }
        }
      ]
    },
    {
      path: '/nlp',
      component: () => import('./views/nlp/Index.vue'),
      children: [
        {
          path: 'check-number',
          name: 'checkNumber',
          component: () => import('./views/nlp/Checknumber.vue'),
          meta: {
            requiresPermissions: ['nlp', 'RO'],
            title: 'Check Number'
          }
        },
        {
          path: 'bulk-clear-cache',
          name: 'bulkClearCache',
          component: () =>
            import('./views/nlp/bulk_clear_cache/BulkClearCache.vue'),
          meta: {
            requiresPermissions: ['nlp', 'RW'],
            title: 'Bulk Clear Cache'
          }
        },
        {
          path: 'bulk-help',
          name: 'BulkClearCachetHelp',
          component: () => import('./views/nlp/bulk_clear_cache/Help.vue'),
          meta: {
            requiresPermissions: ['nlp', 'RO'],
            title: 'Clear Cache Help'
          }
        },
        {
          path: 'bulk-resolver-check',
          name: 'bulkResolverCheck',
          component: () =>
            import('./views/nlp/bulk_resolver_check/BulkResolverCheck.vue'),
          meta: {
            requiresPermissions: ['nlp', 'RO'],
            title: 'Bulk Resolver Check'
          }
        },
        {
          path: 'clear-cache',
          name: 'clearCache',
          component: () => import('./views/nlp/clear_cache/Clearcache.vue'),
          meta: {
            requiresPermissions: ['nlp', 'RW'],
            title: 'Clear Cache'
          }
        },
        {
          path: 'clear-operator-cache',
          name: 'clearOperatorCache',
          component: () =>
            import('./views/nlp/clear_operator_cache/Clearcache.vue'),
          meta: {
            requiresPermissions: ['nlp', 'RW'],
            title: 'Clear Operator Cache'
          }
        },
        {
          path: 'help',
          name: 'clearCachetHelp',
          component: () => import('./views/nlp/clear_cache/Help.vue'),
          meta: {
            requiresPermissions: ['nlp', 'RO'],
            title: 'Clear Cache Help'
          }
        },
        {
          path: 'routes',
          name: 'lookupRouteList',
          component: () => import('./views/nlp/routes/List.vue'),
          meta: {
            requiresPermissions: ['nlp', 'RO'],
            title: 'NLP Routes'
          }
        },
        {
          path: 'routes-create',
          name: 'lookupRouteCreate',
          component: () => import('./views/nlp/routes/Create.vue'),
          meta: {
            requiresPermissions: ['nlp', 'RW'],
            title: 'NLP Create Route'
          }
        },
        {
          path: 'apikey',
          name: 'ApiKeys',
          component: () => import('./views/nlp/manage_apikey/List.vue'),
          meta: {
            requiresPermissions: ['nlp', 'RO'],
            title: 'NLP API Keys'
          }
        },
        {
          path: 'apikey-create',
          name: 'ApiKeysCreate',
          component: () => import('./views/nlp/manage_apikey/Create.vue'),
          meta: {
            requiresPermissions: ['nlp', 'RW'],
            title: 'NLP Create API Key'
          }
        }
      ]
    },
    {
      path: '/twilio_operator_mapping',
      component: () => import('./views/nlp/twilio_operator_mapping/Index.vue'),
      children: [
        {
          path: '',
          name: 'twilioOperatorMappingList',
          component: () =>
            import('./views/nlp/twilio_operator_mapping/List.vue'),
          meta: {
            requiresPermissions: ['nlp', 'RO'],
            title: 'NLP Twilio Operator Mappings'
          }
        },
        {
          path: 'create',
          name: 'twilioOperatorMappingCreate',
          component: () =>
            import('./views/nlp/twilio_operator_mapping/Create.vue'),
          meta: {
            requiresPermissions: ['nlp', 'RW'],
            title: 'NLP Create Twilio Operator Mapping'
          }
        }
      ]
    },
    {
      path: '/webscraper_operator_mapping',
      component: () =>
        import('./views/nlp/webscraper_operator_mapping/Index.vue'),
      children: [
        {
          path: '',
          name: 'localUsaOperatorMappingList',
          component: () =>
            import('./views/nlp/webscraper_operator_mapping/List.vue'),
          meta: {
            requiresPermissions: ['nlp', 'RO'],
            title: 'NLP Local USA Operator Mappings'
          }
        },
        {
          path: 'create',
          name: 'localUsaOperatorMappingCreate',
          component: () =>
            import('./views/nlp/webscraper_operator_mapping/Create.vue'),
          meta: {
            requiresPermissions: ['nlp', 'RW'],
            title: 'NLP Create Local USA Operator Mapping'
          }
        }
      ]
    },
    {
      path: '/neustar_operator_mapping',
      component: () => import('./views/nlp/neustar_operator_mapping/Index.vue'),
      children: [
        {
          path: '',
          name: 'neustarOperatorMappingList',
          component: () =>
            import('./views/nlp/neustar_operator_mapping/List.vue'),
          meta: {
            requiresPermissions: ['nlp', 'RO'],
            title: 'NLP Neustar Operator Mappings'
          }
        },
        {
          path: 'create',
          name: 'neustarOperatorMappingCreate',
          component: () =>
            import('./views/nlp/neustar_operator_mapping/Create.vue'),
          meta: {
            requiresPermissions: ['nlp', 'RW'],
            title: 'NLP Create Neustar Operator Mapping'
          }
        }
      ]
    },
    {
      path: '/iconectiv_operator_mapping',
      component: () =>
        import('./views/nlp/iconectiv_operator_mapping/Index.vue'),
      children: [
        {
          path: '',
          name: 'iconectivOperatorMappingList',
          component: () =>
            import('./views/nlp/iconectiv_operator_mapping/List.vue'),
          meta: {
            requiresPermissions: ['nlp', 'RO'],
            title: 'NLP Iconectiv Operator Mappings'
          }
        },
        {
          path: 'create',
          name: 'iconectivOperatorMappingCreate',
          component: () =>
            import('./views/nlp/iconectiv_operator_mapping/Create.vue'),
          meta: {
            requiresPermissions: ['nlp', 'RW'],
            title: 'NLP Create Iconectiv Operator Mapping'
          }
        }
      ]
    },
    {
      path: '/local_operator_mapping',
      component: () => import('./views/nlp/local_operator_mapping/Index.vue'),
      children: [
        {
          path: '',
          name: 'localOperatorMappingList',
          component: () =>
            import('./views/nlp/local_operator_mapping/List.vue'),
          meta: {
            requiresPermissions: ['nlp', 'RO'],
            title: 'NLP Local Operator Mappings'
          }
        },
        {
          path: 'create',
          name: 'localOperatorMappingCreate',
          component: () =>
            import('./views/nlp/local_operator_mapping/Create.vue'),
          meta: {
            requiresPermissions: ['nlp', 'RW'],
            title: 'NLP Create Local Operator Mapping'
          }
        }
      ]
    },
    {
      path: '/sms-templates',
      component: () => import('./views/sms_template/Index.vue'),
      children: [
        {
          path: '',
          name: 'smsTemplateList',
          component: () => import('./views/sms_template/List.vue'),
          meta: {
            requiresPermissions: ['sms_notification_configuration', 'RO'],
            title: 'SMS Templates'
          }
        },
        {
          path: 'create',
          name: 'smsTemplateCreate',
          component: () => import('./views/sms_template/Create.vue'),
          meta: {
            requiresPermissions: ['sms_notification_configuration', 'RW'],
            title: 'Create SMS Template'
          }
        }
      ]
    },
    {
      path: '/sms-configurations',
      component: () => import('./views/sms_configuration/Index.vue'),
      children: [
        {
          path: '',
          name: 'smsConfigurationList',
          component: () => import('./views/sms_configuration/List.vue'),
          meta: {
            requiresPermissions: ['sms_notification_configuration', 'RO'],
            title: 'SMS Notifications'
          }
        },
        {
          path: 'create',
          name: 'smsConfigurationCreate',
          component: () => import('./views/sms_configuration/Create.vue'),
          meta: {
            requiresPermissions: ['sms_notification_configuration', 'RW'],
            title: 'Create SMS Notification'
          }
        }
      ]
    },
    {
      path: '/sms-blacklist',
      component: () => import('./views/sms_blacklist/Index.vue'),
      children: [
        {
          path: '',
          name: 'smsBlacklistList',
          component: () => import('./views/sms_blacklist/List.vue'),
          meta: {
            requiresPermissions: ['blacklist', 'RO'],
            title: 'SMS Blacklist'
          }
        },
        {
          path: 'create',
          name: 'smsBlacklistCreate',
          component: () => import('./views/sms_blacklist/Create.vue'),
          meta: {
            requiresPermissions: ['blacklist', 'RO'],
            title: 'Create SMS Blacklist'
          }
        }
      ]
    },
    {
      path: '/ip-blacklist',
      component: () => import('./views/ip_blacklist/Index.vue'),
      children: [
        {
          path: '',
          name: 'ipBlacklistList',
          component: () => import('./views/ip_blacklist/List.vue'),
          meta: {
            requiresPermissions: ['ip_configuration', 'RO'],
            title: 'IP Blacklist'
          }
        },
        {
          path: 'create',
          name: 'ipBlacklistCreate',
          component: () => import('./views/ip_blacklist/Create.vue'),
          meta: {
            requiresPermissions: ['ip_configuration', 'RO'],
            title: 'Create IP Blacklist'
          }
        }
      ]
    },
    {
      path: '/credit_party_account_number_blacklist',
      component: () =>
        import('./views/credit_party_account_number_blacklist/Index.vue'),
      children: [
        {
          path: '',
          name: 'CreditPartyAccountNumberBlacklist',
          component: () =>
            import('./views/credit_party_account_number_blacklist/List.vue'),
          meta: {
            requiresPermissions: ['limitations', 'RO'],
            title: 'Credit Party Account Number Blacklist'
          }
        },
        {
          path: 'create',
          name: 'CreditPartyAccountNumberBlacklistCreate',
          component: () =>
            import('./views/credit_party_account_number_blacklist/Create.vue'),
          meta: {
            requiresPermissions: ['limitations', 'RW'],
            title: 'Create Credit Party Account Number Blacklist'
          }
        }
      ]
    },
    {
      path: '/credit_party_mobile_number_blacklist',
      component: () =>
        import('./views/credit_party_mobile_number_blacklist/Index.vue'),
      children: [
        {
          path: '',
          name: 'CreditPartyMobileNumberBlacklist',
          component: () =>
            import('./views/credit_party_mobile_number_blacklist/List.vue'),
          meta: {
            requiresPermissions: ['limitations', 'RO'],
            title: 'Credit Party Mobile Number Blacklist'
          }
        },
        {
          path: 'create',
          name: 'CreditPartyMobileNumberBlacklistCreate',
          component: () =>
            import('./views/credit_party_mobile_number_blacklist/Create.vue'),
          meta: {
            requiresPermissions: ['limitations', 'RW'],
            title: 'Create Credit Party Mobile Number Blacklist'
          }
        }
      ]
    },
    {
      path: '/nuclear_msisdn_length_whitelist',
      component: () =>
        import('./views/nuclear_msisdn_length_whitelist/Index.vue'),
      children: [
        {
          path: '',
          name: 'nuclearMsisdnLengthWhitelistList',
          component: () =>
            import('./views/nuclear_msisdn_length_whitelist/List.vue'),
          meta: {
            requiresPermissions: ['limitations', 'RO'],
            title: 'MSISDN Length Whitelist'
          }
        },
        {
          path: 'create',
          name: 'nuclearMsisdnLengthWhitelistCreate',
          component: () =>
            import('./views/nuclear_msisdn_length_whitelist/Create.vue'),
          meta: {
            requiresPermissions: ['limitations', 'RO'],
            title: 'Create MSISDN Length Whitelist'
          }
        }
      ]
    },
    {
      path: '/campaigns',
      component: () => import('./views/promotions/Index.vue'),
      children: [
        {
          path: '/campaigns/index',
          name: 'promotions-list',
          component: () => import('./views/promotions/List.vue')
        },
        {
          path: '/campaigns/create',
          name: 'promotions-create',
          component: () => import('./views/promotions/Edit.vue')
        },
        {
          path: '/campaigns/:promotion_id/edit',
          name: 'promotions-edit',
          component: () => import('./views/promotions/Edit.vue')
        }
      ]
    },
    {
      path: '/orange/link_operator',
      component: () => import('./views/orange/link_operator/Index.vue'),
      children: [
        {
          path: '',
          name: 'orangeLinkOperatorList',
          component: () => import('./views/orange/link_operator/List.vue'),
          meta: {
            requiresPermissions: ['orange', 'RO'],
            title: 'ODM Operators'
          }
        },
        {
          path: 'create',
          name: 'orangeLinkOperatorCreate',
          component: () => import('./views/orange/link_operator/Create.vue'),
          meta: {
            requiresPermissions: ['orange', 'RW'],
            title: 'Link ODM Operator'
          }
        }
      ]
    },

    {
      path: '/jobs_request',
      component: () => import('./views/jobs_request/Index.vue'),
      children: [
        {
          path: '',
          name: 'jobsRequestList',
          component: () => import('./views/jobs_request/List.vue'),
          meta: {
            requiresPermissions: ['jobs_request', 'RO'],
            title: 'Jobs Request'
          }
        },
        {
          path: '',
          name: 'jobsRequestCreate',
          component: () => import('./views/jobs_request/Create.vue'),
          meta: {
            requiresPermissions: ['jobs_request', 'RW'],
            title: 'rerun_jobs_script'
          }
        }
      ]
    },

    {
      path: '/default_country_resource_map',
      component: () => import('./views/default_country_resource_map/Index.vue'),
      children: [
        {
          path: '',
          name: 'defaultCountryResourceMapList',
          component: () =>
            import('./views/default_country_resource_map/List.vue'),
          meta: {
            requiresPermissions: ['customers', 'RO'],
            title: 'Default Country Resource Map'
          }
        },
        {
          path: 'create',
          name: 'defaultCountryResourceMapCreate',
          component: () =>
            import('./views/default_country_resource_map/Create.vue'),
          meta: {
            requiresPermissions: ['customers', 'RW']
          }
        }
      ]
    },
    {
      path: '/terms_and_conditions',
      component: () => import('./views/terms_and_conditions/Index.vue'),
      children: [
        {
          path: '',
          name: 'termsAndConditionsList',
          component: () => import('./views/terms_and_conditions/List.vue'),
          meta: {
            requiresPermissions: ['customers', 'RO'],
            title: 'Terms and Conditions'
          }
        },
        {
          path: 'create',
          name: 'termsAndConditionsCreate',
          component: () => import('./views/terms_and_conditions/Create.vue'),
          meta: {
            requiresPermissions: ['customers', 'RW']
          }
        }
      ]
    },
    {
      path: '/provisioning/nuclear_operator_rate',
      component: () =>
        import('./views/provisioning/nuclear_operator_rate/Index.vue'),
      children: [
        {
          path: '',
          name: 'NuclearOperatorRateList',
          component: () =>
            import('./views/provisioning/nuclear_operator_rate/List.vue'),
          meta: {
            requiresPermissions: ['operator_rate', 'RO']
          }
        },
        {
          path: 'create',
          name: 'NuclearOperatorRateCreate',
          component: () =>
            import('./views/provisioning/nuclear_operator_rate/Create.vue'),
          meta: {
            requiresPermissions: ['operator_rate', 'RW']
          }
        },
        {
          path: 'bulk_update',
          name: 'NuclearOperatorRateBulkUpdate',
          component: () =>
            import('./views/provisioning/nuclear_operator_rate/BulkUpdate.vue'),
          meta: {
            requiresPermissions: ['operator_rate', 'RW'],
            title: 'Bulk Update Nuclear Operator Rate'
          }
        },
        {
          path: 'schedule_bulk_update',
          name: 'NuclearOperatorRateScheduleBulkUpdate',
          component: () =>
            import(
              './views/provisioning/nuclear_operator_rate/schedule/ScheduleBulkUpdate.vue'
            ),
          meta: {
            requiresPermissions: ['operator_rate', 'RW'],
            title: 'Schedule Bulk Update Nuclear Operator Rate'
          }
        }
      ]
    },
    {
      path: '/provisioning/nuclear_operator_rates_mapping',
      component: () =>
        import('./views/provisioning/nuclear_operator_rates_mapping/Index.vue'),
      children: [
        {
          path: '',
          name: 'NuclearOperatorRatesMappingList',
          component: () =>
            import(
              './views/provisioning/nuclear_operator_rates_mapping/List.vue'
            ),
          meta: {
            requiresPermissions: ['operator_rate', 'RO']
          }
        },
        {
          path: 'create',
          name: 'NuclearOperatorRatesMappingCreate',
          component: () =>
            import(
              './views/provisioning/nuclear_operator_rates_mapping/Create.vue'
            ),
          meta: {
            requiresPermissions: ['operator_rate', 'RW']
          }
        },
        {
          path: 'bulk_update',
          name: 'NuclearOperatorRateMappingBulkUpdate',
          component: () =>
            import(
              './views/provisioning/nuclear_operator_rates_mapping/BulkUpdate.vue'
            ),
          meta: {
            requiresPermissions: ['operator_rate', 'RW']
          }
        }
      ]
    },
    {
      path: '/outage_monitoring_configuration',
      component: () =>
        import('./views/outage_monitoring_configuration/Index.vue'),
      children: [
        {
          path: '',
          name: 'OperatorsOutageMonitoringRulesList',
          component: () =>
            import('./views/outage_monitoring_configuration/List.vue'),
          meta: {
            requiresPermissions: ['operators', 'RO'],
            title: 'Outage Monitoring Configuration'
          }
        },
        {
          path: 'create',
          name: 'OperatorsOutageMonitoringRulesCreate',
          component: () =>
            import('./views/outage_monitoring_configuration/Create.vue'),
          meta: {
            requiresPermissions: ['operators', 'RO']
          }
        }
      ]
    },
    {
      path: '/customers_limitation',
      component: () => import('./views/customers_limitation/Index.vue'),
      children: [
        {
          path: '',
          name: 'CustomersLimitationList',
          component: () => import('./views/customers_limitation/List.vue'),
          meta: {
            requiresPermissions: ['customers', 'RO'],
            title: 'Customers Limitation'
          }
        },
        {
          path: 'create',
          name: 'CustomersLimitationCreate',
          component: () => import('./views/customers_limitation/Create.vue'),
          meta: {
            requiresPermissions: ['customers', 'RW']
          }
        }
      ]
    },
    {
      path: '/operators_limitation',
      component: () => import('./views/operators_limitation/Index.vue'),
      children: [
        {
          path: '',
          name: 'OperatorsLimitationList',
          component: () => import('./views/operators_limitation/List.vue'),
          meta: {
            requiresPermissions: ['operators', 'RO'],
            title: 'Operators Limitation'
          }
        },
        {
          path: 'create',
          name: 'OperatorsLimitationCreate',
          component: () => import('./views/operators_limitation/Create.vue'),
          meta: {
            requiresPermissions: ['operators', 'RW']
          }
        }
      ]
    },
    {
      path: '/providers_limitation',
      component: () => import('./views/providers_limitation/Index.vue'),
      children: [
        {
          path: '',
          name: 'ProvidersLimitationList',
          component: () => import('./views/providers_limitation/List.vue'),
          meta: {
            requiresPermissions: ['providers', 'RO'],
            title: 'Providers Limitation'
          }
        },
        {
          path: 'create',
          name: 'ProvidersLimitationCreate',
          component: () => import('./views/providers_limitation/Create.vue'),
          meta: {
            requiresPermissions: ['providers', 'RW']
          }
        }
      ]
    },
    {
      path: '/operators_status',
      component: () => import('./views/operators_status/Index.vue'),
      children: [
        {
          path: '',
          name: 'OperatorsStatusList',
          component: () => import('./views/operators_status/List.vue'),
          meta: {
            requiresPermissions: ['operators_status', 'RO'],
            title: 'Operators Status'
          }
        },
        {
          path: 'create',
          name: 'OperatorStatusCreate',
          component: () => import('./views/operators_status/Create.vue'),
          meta: {
            requiresPermissions: ['operators_status', 'RW']
          }
        }
      ]
    },
    {
      path: '/dvs_customer_mappings',
      component: () => import('./views/dvs_customer_mapping/Index.vue'),
      children: [
        {
          path: '',
          name: 'DvsCustomerMappingList',
          component: () => import('./views/dvs_customer_mapping/List.vue'),
          meta: {
            requirePermissions: ['customers', 'RO'],
            title: 'DVS Customer Mappings'
          }
        },
        {
          path: '/dvs_customer_mapping/customer_detail/create',
          name: 'OracleCustomerCreate',
          component: () =>
            import('./views/dvs_customer_mapping/customer_detail/Create.vue'),
          meta: {
            requiresPermissions: ['customers', 'RW']
          }
        }
      ]
    },
    {
      path: '/notifications',
      component: () => import('./views/notification/Index.vue'),
      children: [
        {
          path: '',
          name: 'NotificationList',
          component: () => import('./views/notification/List.vue'),
          meta: {
            requiresPermissions: ['notifications', 'RW'],
            title: 'Notifications'
          }
        }
      ]
    },
    {
      path: '/notification/topics',
      component: () => import('./views/notification/topics/Index.vue'),
      children: [
        {
          path: '',
          name: 'NotificationTopicList',
          component: () => import('./views/notification/topics/List.vue'),
          meta: {
            requiresPermissions: ['notifications', 'RW'],
            title: 'Notification Topics'
          }
        },
        {
          path: 'create',
          name: 'NotificationTopicCreate',
          component: () => import('./views/notification/topics/Create.vue'),
          meta: {
            requiresPermissions: ['notifications', 'RW'],
            title: 'Create Notification Topic'
          }
        }
      ]
    },
    {
      path: '/notification/groups',
      component: () => import('./views/notification/groups/Index.vue'),
      children: [
        {
          path: '',
          name: 'NotificationGroupList',
          component: () => import('./views/notification/groups/List.vue'),
          meta: {
            requiresPermissions: ['notifications', 'RW'],
            title: 'Notification Groups'
          }
        },
        {
          path: 'create',
          name: 'NotificationGroupCreate',
          component: () => import('./views/notification/groups/Create.vue'),
          meta: {
            requiresPermissions: ['notifications', 'RW'],
            title: 'Create Notification Group'
          }
        }
      ]
    },
    {
      path: '/notification/users',
      component: () => import('./views/notification/users/Index.vue'),
      children: [
        {
          path: '',
          name: 'NotificationUserList',
          component: () => import('./views/notification/users/List.vue'),
          meta: {
            requiresPermissions: ['notifications', 'RW'],
            title: 'Notification Users'
          }
        },
        {
          path: 'create',
          name: 'NotificationUserCreate',
          component: () => import('./views/notification/users/Create.vue'),
          meta: {
            requiresPermissions: ['notifications', 'RW'],
            title: 'Create Notification User'
          }
        }
      ]
    },
    {
      path: '/reports',
      component: () => import('./views/report/Index.vue'),
      children: [
        {
          path: 'customer',
          name: 'customerStats',
          component: () => import('./views/report/CustomerStats.vue'),
          meta: {
            requiresPermissions: ['report', 'RO'],
            title: 'Report - Customer'
          }
        },
        {
          path: 'provider',
          name: 'providerStats',
          component: () => import('./views/report/ProviderStats.vue'),
          meta: {
            requiresPermissions: ['report', 'RO'],
            title: 'Report - Provider'
          }
        },
        {
          path: 'operators_linking',
          name: 'OperatorsLinking',
          component: () =>
            import('./views/report/operators_linking/OperatorsLinking.vue'),
          meta: {
            requiresPermissions: ['report', 'RW'],
            title: 'Report - Operators Linking'
          }
        },
        {
          path: '/operators_linking/create',
          name: 'OperatorLinkingCreate',
          component: () =>
            import('./views/report/operators_linking/Create.vue'),
          meta: {
            requiresPermissions: ['report', 'RW']
          }
        },
        {
          path: 'operators_linking_fmuser',
          name: 'OperatorsLinkingFmuser',
          component: () =>
            import(
              './views/report/operators_linking_fmuser/OperatorsLinkingFmuser.vue'
            ),
          meta: {
            requiresPermissions: ['report', 'RW'],
            title: 'Report - Operators Linking Fmuser'
          }
        },
        {
          path: 'operators_linking_fmuser',
          name: 'OperatorsLinkingFmuserCreate',
          component: () =>
            import('./views/report/operators_linking_fmuser/Create.vue'),
          meta: {
            requiresPermissions: ['report', 'RW'],
            title: 'Report: Operators Linking Fmuser'
          }
        },
        {
          path: 'customer_activity_monitoring_rules',
          name: 'CustomerActivityMonitoringRules',
          component: () =>
            import(
              './views/report/customer_activity_monitoring_rules/CustomerActivityMonitoringRules.vue'
            ),
          meta: {
            requiresPermissions: ['report', 'RW'],
            title: 'Customer Activity Monitoring Rules'
          }
        },
        {
          path: '/customer_activity_monitoring_rules/create',
          name: 'CustomerActivityMonitoringRulesCreate',
          component: () =>
            import(
              './views/report/customer_activity_monitoring_rules/Create.vue'
            ),
          meta: {
            requiresPermissions: ['report', 'RW']
          }
        },
        {
          path: 'numbering_plan_india',
          name: 'NumberingPlanIndia',
          component: () =>
            import('./views/report/numbering_plan_india/List.vue'),
          meta: {
            requiresPermissions: ['report', 'RO'],
            title: 'Numbering Plan India'
          }
        },
        {
          path: 'numbering_plan_india/create',
          name: 'NumberingPlanIndiaCreate',
          component: () =>
            import('./views/report/numbering_plan_india/Create.vue'),
          meta: {
            requiresPermissions: ['report', 'RW']
          }
        },
        {
          path: 'advance',
          name: 'AdvanceReportList',
          component: () => import('./views/report/advance/List.vue'),
          meta: {
            requiresPermissions: ['report', 'RO'],
            title: 'Report: Advance'
          }
        },
        {
          path: 'provider_offers',
          name: 'ProviderOffers',
          component: () => import('./views/report/provider_offers/List.vue'),
          meta: {
            requiresPermissions: ['pricelists', 'RW']
          }
        }
      ]
    },
    {
      path: '/monitoring_logs',
      component: () => import('./views/monitoring_log/Index.vue'),
      children: [
        {
          path: '',
          name: 'MonitoringLogList',
          component: () => import('./views/monitoring_log/List.vue'),
          meta: {
            requiresPermissions: ['script_monitoring', 'RW'],
            title: 'Monitoring Logs'
          }
        }
      ]
    },
    {
      path: '/email_send_logs',
      component: () => import('./views/email_send_log/Index.vue'),
      children: [
        {
          path: '',
          name: 'EmailSendLogList',
          component: () => import('./views/email_send_log/List.vue'),
          meta: {
            requiresPermissions: ['report', 'RO'],
            title: 'Report - Email Send Log'
          }
        }
      ]
    },
    {
      path: '/product_mapping_router',
      component: () =>
        import('./views/product_mapping/product_mapping_router/Index.vue'),
      children: [
        {
          path: '',
          name: 'ProductNatList',
          component: () =>
            import('./views/product_mapping/product_mapping_router/List.vue'),
          meta: {
            requiresPermissions: ['products', 'RO'],
            title: 'Product Nat'
          }
        },
        {
          path: 'create',
          name: 'ProductNatCreate',
          component: () =>
            import('./views/product_mapping/product_mapping_router/Create.vue'),
          meta: {
            requiresPermissions: ['products', 'RW'],
            title: 'Create Product Nat'
          }
        }
      ]
    },
    {
      path: '/product_mapping_modules',
      component: () =>
        import('./views/product_mapping/product_mapping_modules/Index.vue'),
      children: [
        {
          path: '',
          name: 'ProductNatModList',
          component: () =>
            import('./views/product_mapping/product_mapping_modules/List.vue'),
          meta: {
            requiresPermissions: ['products', 'RO'],
            title: 'Product Nat Mod'
          }
        },
        {
          path: 'create',
          name: 'ProductNatModCreate',
          component: () =>
            import(
              './views/product_mapping/product_mapping_modules/Create.vue'
            ),
          meta: {
            requiresPermissions: ['products', 'RW'],
            title: 'Create Product Nat Mod'
          }
        }
      ]
    },
    {
      path: '/sms_costs',
      component: () => import('./views/sms_costs/Index.vue'),
      children: [
        {
          path: '',
          name: 'SmsCostList',
          component: () => import('./views/sms_costs/List.vue'),
          meta: {
            requiresPermissions: ['sms_mgt', 'RO'],
            title: 'Sms pricelists costs list'
          }
        },
        {
          path: 'create',
          name: 'SmsCostCreate',
          component: () => import('./views/sms_costs/Create.vue'),
          meta: {
            requiresPermissions: ['sms_mgt', 'RW'],
            title: 'Create SMS cost for pricelist'
          }
        },
        {
          path: 'import',
          name: 'SmsCostImport',
          component: () => import('./views/sms_costs/Import.vue'),
          meta: {
            requiresPermissions: ['sms_mgt', 'RW'],
            title: 'Import SMS cost for pricelist'
          }
        }
      ]
    },
    {
      path: '/sms_smsc',
      component: () => import('./views/sms_smsc/Index.vue'),
      children: [
        {
          path: '',
          name: 'SmsSmscList',
          component: () => import('./views/sms_smsc/List.vue'),
          meta: {
            requiresPermissions: ['sms_mgt', 'RO'],
            title: 'Sms pricelists costs list'
          }
        },
        {
          path: 'create',
          name: 'SmsSmscCreate',
          component: () => import('./views/sms_smsc/Create.vue'),
          meta: {
            requiresPermissions: ['sms_mgt', 'RW'],
            title: 'Create SMS cost for pricelist'
          }
        }
      ]
    },
    {
      path: '/sms_routing',
      component: () => import('./views/sms_routing/Index.vue'),
      children: [
        {
          path: '',
          name: 'SmsRoutingList',
          component: () => import('./views/sms_routing/List.vue'),
          meta: {
            requiresPermissions: ['sms_mgt', 'RO'],
            title: 'Sms routing'
          }
        },
        {
          path: 'create',
          name: 'SmsRouteCreate',
          component: () => import('./views/sms_routing/Create.vue'),
          meta: {
            requiresPermissions: ['sms_mgt', 'RW'],
            title: 'Create SMS route'
          }
        },
        {
          path: 'country',
          name: 'SmsRouteCountry',
          component: () => import('./views/sms_routing/Country.vue'),
          meta: {
            requiresPermissions: ['sms_mgt', 'RW'],
            title: 'Create SMS route for country'
          }
        }
      ]
    },
    {
      path: '/nuclear_sms',
      component: () => import('./views/nuclear_sms/Index.vue'),
      children: [
        {
          path: '',
          name: 'nuclearSmsList',
          component: () => import('./views/nuclear_sms/List.vue'),
          meta: {
            requiresPermissions: ['sms_notification_configuration', 'RO'],
            title: 'Legacy SMS Configurations'
          }
        },
        {
          path: 'create',
          name: 'nuclearSmsCreate',
          component: () => import('./views/nuclear_sms/Create.vue'),
          meta: {
            requiresPermissions: ['sms_notification_configuration', 'RW'],
            title: 'Create Legacy SMS Configuration'
          }
        }
      ]
    },
    {
      path: '/changelog',
      component: () => import('./views/changelog/Index.vue'),
      children: [
        {
          path: '',
          name: 'ChangelogList',
          component: () => import('./views/changelog/List.vue'),
          meta: {
            requiresPermissions: ['changelog', 'RW'],
            title: 'Changelog routing'
          }
        }
      ]
    },
    {
      path: '/activity_history',
      component: () => import('./views/activity_history/Index.vue'),
      children: [
        {
          path: '',
          name: 'ActivityHistoryList',
          component: () => import('./views/activity_history/List.vue'),
          meta: {
            requiresPermissions: ['activity_history', 'RW'],
            title: 'Activity history routing'
          }
        }
      ]
    },
    {
      path: '/user_preferences',
      component: () => import('./views/user_preferences/Index.vue'),
      children: [
        {
          path: '',
          name: 'UserPreferences',
          component: () => import('./views/user_preferences/Preferences.vue')
        }
      ]
    }
  ],

  parseQuery(query) {
    return qs.parse(query);
  },
  stringifyQuery(query) {
    const result = qs.stringify(query);
    return result ? '?' + result : '';
  }
});

function guardCheck(user, to, next) {
  if (!to.meta.requiresPermissions || user.info.permissions.admin) {
    return next();
  }
  let permission = to.meta.requiresPermissions[0];
  if (
    user.info.permissions[permission] &&
    (user.info.permissions[permission] === 'RW' ||
      user.info.permissions[permission] === to.meta.requiresPermissions[1])
  ) {
    return next();
  }
  console.log('Not allowed to access this');
  next({
    name: 'forbidden'
  });
}

router.beforeEach((to, from, next) => {
  if (to.path === '/sso/callback') {
    return next();
  } else if (getToken()) {
    if (
      getToken() &&
      !(store.state.me && store.state.me.info && store.state.me.info.id)
    ) {
      store.dispatch('me/getItem').then(response => {
        guardCheck(response, to, next);
      });
    } else {
      guardCheck(store.state.me, to, next);
    }
  } else {
    localStorage.setItem('post_login_redirect', to.fullPath);
    next(false);
    window.location.replace(`${process.env.VUE_APP_API}/sso/login`);
  }
});

const APP_TITLE = 'Admin';
router.afterEach((to, from) => {
  // Use next tick to handle router history correctly
  // see: https://github.com/vuejs/vue-router/issues/914#issuecomment-384477609
  Vue.nextTick(() => {
    document.title =
      to.meta && to.meta.title ? `${APP_TITLE}: ${to.meta.title}` : APP_TITLE;
  });
});

export default router;
