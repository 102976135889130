import constructStore from './base';
import Vue from 'vue';

const listState = {
  filters: {},
  orfilters: {},
  sort: ['-me.id'],
  include: [],
  page: {
    number: 1,
    size: 20
  },
  count: 0,
  permissions: []
};

const listStore = constructStore(listState, 'permissions', 'permissions');

listStore.getters.getPermissions = state => {
  state.permissions.forEach(permission => {
    if (!permission.updateFields) {
      Vue.set(permission, 'updateFields', {
        name: permission.name
      });
    }
  });
  return state.permissions;
};

const itemState = {
  include: [],
  permission: {}
};

const itemStore = constructStore(itemState, 'permission', 'permissions');

export default {
  list: listStore,
  item: itemStore
};
