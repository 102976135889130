import { get } from '@/common/api';
import constructStore from './base';
import Vue from 'vue';

const listState = {
  filters: {
    'me.deleted:eq': false,
    'operator.is_active:eq': true
  },
  orfilters: {},
  sort: ['-me.id'],
  include: [
    'operator.country',
    'service',
    'product_type',
    'apo.airtime_operator',
    'product_tag_configurations.tag',
    'product_benefits.unit',
    'goods_services_product_override.gns_operator'
  ],
  page: {
    number: 1,
    size: 50
  },
  count: 0,
  products: []
};

const listStore = constructStore(listState, 'products', 'products');

listStore.getters.getProducts = state => {
  return state.products;
};

const itemState = {
  include: [
    'operator.country',
    'service',
    'product_type',
    'apo.airtime_operator',
    'product_tag_configurations.tag',
    'product_benefits.unit',
    'goods_services_product_override.gns_operator'
  ],
  sort: [],
  product: {}
};

const itemStore = constructStore(itemState, 'product', 'products');

export default {
  list: listStore,
  item: itemStore
};
