import { get } from '@/common/api';

export default {
  namespaced: true,
  state: {
    _items: [],
    _query: {
      include: ['country.region'],
      filters: {
        'me.is_active:eq': true,
        'me.is_country:eq': false
      },
      sort: ['operator']
    }
  },
  getters: {
    items: state => state._items,
    nexus: state => {
      return state._items.filter(operator => !operator.parent_operator_id);
    }
  },
  mutations: {
    setList(state, data) {
      state._items = data;
    }
  },
  actions: {
    async fetchList({ commit, state }) {
      const response = await get('operators', state._query);
      commit('setList', response.data.data);
    }
  }
};
