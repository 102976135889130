import constructStore from './base';

const listState = {
  filters: {},
  orfilters: {},
  sort: ['-me.id'],
  include: ['account', 'country', 'customer', 'operator', 'provider'],
  page: {
    number: 1,
    size: 20
  },
  count: 0,
  blacklists: []
};

const listStore = constructStore(
  listState,
  'blacklists',
  'sms_notification_blacklists'
);

listStore.getters.getBlacklists = state => {
  return state.blacklists;
};

const itemState = {
  include: ['account', 'country', 'customer', 'operator', 'provider'],
  sort: [],
  blacklist: {}
};

const itemStore = constructStore(
  itemState,
  'blacklist',
  'sms_notification_blacklists'
);

export default {
  list: listStore,
  item: itemStore
};
