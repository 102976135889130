import { get } from '@/common/api';

export default {
  namespaced: true,
  state: {
    _items: [],
    _query: {
      filters: {
        'me.active:eq': true
      },
      sort: ['namespace']
    }
  },
  getters: {
    items: state => state._items
  },
  mutations: {
    setList(state, data) {
      state._items = data;
    }
  },
  actions: {
    async fetchList({ commit, state }) {
      const response = await get('modules', state._query);
      commit('setList', response.data.data);
    }
  }
};
