<template>
  <div>
    <b-loading :active.sync="isLoading"></b-loading>
    <quick-view :show="showHistoryPane" @hide="closeHistory">
      <template #title>
        <span>{{ title }}</span>
      </template>
      <template #body>
        <table class="table is-fullwidth">
          <thead>
            <th>Changes</th>
            <th>Action</th>
            <th>Source</th>
            <th>Comment</th>
            <th>By</th>
            <th>Date</th>
          </thead>
          <tbody>
            <tr v-for="(entry, index) of historyItems" :key="index">
              <td v-if="entry.changes">
                <ul>
                  <li v-for="(item, i) in entry.changes" :key="i">
                    <section
                      v-if="
                        entry.resource === 'balance' &&
                        item.property === 'amount'
                      "
                    >
                      <span class="has-text-success"> {{ item.new }}</span>
                      <span class="has-text-info">{{ item.property }}</span> has
                      been added to wallet
                    </section>
                    <section
                      v-else-if="
                        entry.action == 'CREATE' ||
                        (itemValue(item.old) === 'All' && isBoolean(item.new))
                      "
                    >
                      <span class="has-text-info">{{ item.property }}</span>
                      set to
                      <span class="has-text-success">{{
                        itemValue(item.new)
                      }}</span>
                    </section>
                    <section v-else>
                      <span class="has-text-info">{{ item.property }}</span>
                      changed from
                      <span class="has-text-danger">{{
                        itemValue(item.old)
                      }}</span>
                      to
                      <span class="has-text-success">{{
                        itemValue(item.new)
                      }}</span>
                    </section>
                  </li>
                </ul>
              </td>
              <td v-else>Created</td>
              <td>{{ entry.action }}</td>
              <td>{{ entry.resource }}</td>
              <td>{{ entry.notes }}</td>
              <td>
                {{ entry.created_by && entry.created_by.username }}
              </td>
              <td>
                <b-tooltip :dashed="true" :label="entry.created_at | utcDate">
                  {{ entry.created_at | humanDate }} ago
                </b-tooltip>
              </td>
            </tr>
          </tbody>
        </table>
      </template>
    </quick-view>
  </div>
</template>
<script>
import { get, put } from '@/common/api';
import { diff } from '@/common/helpers';
import { isNil, omit } from 'ramda';

export default {
  name: 'RevisionHistory',
  props: [
    'resource',
    'parentResource',
    'resourceId',
    'excludeResource',
    'selected',
    'title'
  ],
  data() {
    return {
      showHistoryPane: false,
      isLoading: false,
      historyItems: [],
      skipped_fields: ['updated_at', 'updated_by', 'created_at']
    };
  },
  watch: {
    selected: {
      handler(newValue, oldValue) {
        if (newValue) {
          this.showHistory();
        }
      }
    }
  },
  methods: {
    itemValue(item) {
      return isNil(item) ? 'All' : item;
    },
    closeHistory() {
      this.showHistoryPane = false;
      this.$emit('close');
    },
    isBoolean(val) {
      return val === false || val === true;
    },
    showHistory() {
      let historyList;
      this.isLoading = true;

      let filters = {
        sort: ['-me.created_at'],
        include: ['created_by']
      };

      if (this.resource && !this.parentResource) {
        filters['filters'] = {
          'me.resource:eq': this.resource,
          'me.resource_id:eq': this.selected.id
        };
        filters['orfilters'] = {
          'me.parent_resource:eq': this.resource,
          'me.parent_resource_id:eq': this.selected.id
        };
      } else if (this.parentResource && this.excludeResource) {
        filters['filters'] = {
          'me.parent_resource:eq': this.parentResource,
          'me.parent_resource_id:eq': this.selected.id,
          'me.resource:-eq': this.excludeResource
        };
      } else if (this.resource && this.parentResource) {
        filters['filters'] = {
          'me.resource:eq': this.resource,
          'me.parent_resource:eq': this.parentResource
        };

        if (this.selected.id) {
          filters['filters']['me.parent_resource_id:eq'] = this.selected.id;
        }
        if (this.resourceId) {
          filters['filters']['me.resource_id:eq'] = this.resourceId.id;
        }
      }

      get('revision_histories', filters).then(response => {
        historyList = response.data.data.reverse();
        let previous_item_history = {};
        historyList.forEach((item, index, list) => {
          const diff_item = JSON.parse(item.diff);
          const prev_item =
            previous_item_history[item.resource + '_' + item.resource_id] || {};
          const current_item_history = { ...prev_item, ...diff_item };
          let changes = diff(
            omit(this.skipped_fields, current_item_history),
            prev_item
          );

          item.action_show = item.action;
          if (
            (item.resource === item.parent_resource &&
              item.action !== 'CREATE') ||
            item.resource !== item.parent_resource
          ) {
            item.changes = changes;
            previous_item_history[item.resource + '_' + item.resource_id] =
              current_item_history;
          }
          if (item.resource !== item.parent_resource) {
            item.action_show += ':' + item.resource + ' id:' + item.resource_id;
          }
        });
        this.isLoading = false;
        this.historyItems = historyList.reverse();
        this.showHistoryPane = true;
      });
    }
  }
};
</script>
