import { get, post } from '@/common/api';
import { clone } from 'ramda';

// TODO: Instead of using closure, use class
export default function constructStore(state, resource, path) {
  const initState = clone(state);
  return {
    namespaced: true,
    state,
    getters: {},
    mutations: {
      setList(state, data) {
        state[resource] = data.data;
        state.count = data.count;
      },
      setItem(state, data) {
        state[resource] = data;
      },
      setFilter(state, filter) {
        state[resource] = [];
        state.count = 0;
        state.page && state.page.number ? (state.page.number = 1) : null;

        state.filters = { ...state.filters, ...filter };
      },
      setOrFilter(state, filter) {
        state[resource] = [];
        state.count = 0;
        state.page && state.page.number ? (state.page.number = 1) : null;

        state.orfilters = { ...state.orfilters, ...filter };
      },
      setSort(state, sort) {
        state.sort = sort;
      },
      dropItem(state, index) {
        state[resource].splice(index, 1);
      },
      addItem(state, item) {
        state[resource].unshift(item);
      },
      setInclude(state, include) {
        state.include = include;
      },
      setPage(state, number) {
        state.page.number = number;
      },
      resetFilter(state) {
        state.filters = clone(initState.filters);
      },
      resetState(state) {
        Object.assign(state, clone(initState));
      }
    },
    actions: {
      getList({ commit, state }) {
        get(path, {
          filters: JSON.parse(JSON.stringify(state.filters)),
          orfilters: JSON.parse(JSON.stringify(state.orfilters)),
          sort: JSON.parse(JSON.stringify(state.sort)),
          include: JSON.parse(JSON.stringify(state.include)),
          page: JSON.parse(JSON.stringify(state.page))
        }).then(response => {
          commit('setList', response.data);
        });
      },
      async getItem({ commit, state }, id) {
        let response = await get(`${path}/${id}`, {
          include: JSON.parse(JSON.stringify(state.include))
        });
        commit('setItem', response.data);
        return response.data;
      },
      createItem({ commit, state }, body) {
        post(path, body).then(response => {
          commit('setItem', response.data);
        });
      }
    }
  };
}
