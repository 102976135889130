import { get } from '@/common/api';

export default function (path) {
  return {
    namespaced: true,
    state: {
      _items: [],
      _query: {
        filters: {},
        sort: []
      }
    },
    getters: {
      items: state => state._items
    },
    mutations: {
      setList(state, data) {
        state._items = data;
      }
    },
    actions: {
      fetchList({ commit, state }) {
        get(path, state._query)
          .then(response => {
            commit('setList', response.data.data);
          })
          .catch(console.log);
      }
    }
  };
}
