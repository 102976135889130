<template>
  <b-dropdown
    v-model="items"
    aria-role="list"
    multiple
    class="nx-multi-select autocomplete"
    @active-change="setSearchInputFocus"
    @change="setValue(items)"
  >
    <template #trigger>
      <div class="taginput control">
        <div class="taginput-container is-focusable">
          <template v-if="items.length">
            <span
              v-if="maxItems && items.length > maxItems"
              class="placeholder has-text-grey"
              >Selected ({{ items.length }})</span
            >
            <template v-else>
              <b-tag
                v-for="(tag, index) of items"
                :key="tag.id"
                :tabstop="false"
                :ellipsis="true"
                :closable="true"
                @close="removeItem(index)"
              >
                <span v-if="hasTagSlot">
                  <slot name="tag" :tag="tag"></slot>
                </span>
                <span v-else>
                  {{ tag[field] }}
                </span>
              </b-tag>
            </template>
          </template>
          <span v-else class="placeholder has-text-grey">{{
            placeholder
          }}</span>
        </div>
      </div>
    </template>
    <b-dropdown-item aria-role="listitem" custom :focusable="false">
      <b-input
        ref="input"
        v-model="searchText"
        type="text"
        icon="magnify"
        @input="setHovered(0)"
        @keydown.native.enter.prevent="enterPressed"
        @keydown.native.up.prevent="keyArrows('up')"
        @keydown.native.down.prevent="keyArrows('down')"
      >
      </b-input>
      <div class="nx-multi-select__bulk-actions">
        <a
          class="link nx-multi-select__bulk-action nx-tag-space"
          @click="selectAll"
          >Select All</a
        >
        <a class="link nx-multi-select__bulk-action" @click="deselectAll"
          >Deselect All</a
        >
      </div>
    </b-dropdown-item>
    <b-dropdown-item
      v-for="item of items"
      :key="item.id * 1000"
      aria-role="listitem"
      :value="item"
      @click="removeFromSelectionMenu(item)"
    >
      <span v-if="hasOptionSlot">
        <slot name="option" :option="item"></slot>
      </span>
      <span v-else>
        {{ item[field] }}
      </span>
    </b-dropdown-item>
    <b-dropdown-item separator></b-dropdown-item>
    <b-dropdown-item
      v-for="(item, index) of filteredItems"
      :key="item.id"
      aria-role="listitem"
      :value="item"
      :class="{ 'is-hovered': index === hoveredIndex }"
    >
      <span v-if="hasOptionSlot">
        <slot name="option" :option="item"></slot>
      </span>
      <span v-else>
        {{ item[field] }}
      </span>
    </b-dropdown-item>
  </b-dropdown>
</template>

<script>
import { get } from '@/common/api';
import { scrollUp } from '@/common/helpers';
import fuse from 'fuse.js';

export default {
  name: 'NxMultiSelect',
  props: [
    'resource',
    'field',
    'params',
    'placeholder',
    'dataFilter',
    'value',
    'customKey',
    'maxItems',
    'passedData'
  ],
  data() {
    return {
      items: [],
      itemList: [],
      itemSearchBox: [],
      searchText: '',
      hoveredIndex: null,
      isUpdated: false
    };
  },
  computed: {
    hasOptionSlot() {
      return this.$scopedSlots.option;
    },
    hasTagSlot() {
      return this.$scopedSlots.tag;
    },
    selectedKey() {
      return this.customKey ? this.customKey : 'id';
    },
    filteredItems() {
      if (!this.searchText) {
        return this.itemList;
      }
      try {
        scrollUp(this.$el);
      } catch (error) {
        console.log(error);
      }
      return this.itemSearchBox.search(this.searchText.trim());
    }
  },
  created() {
    if (!this.passedData) {
      get(this.resource, this.params).then(response => {
        this.setData(response.data.data);
      });
    } else {
      this.setData(this.passedData);
    }
  },
  methods: {
    enterPressed() {
      let index = this.items.findIndex(
        item =>
          this.filteredItems[this.hoveredIndex][this.selectedKey] ===
          item[this.selectedKey]
      );
      if (index > -1) {
        this.items.splice(index, 1);
      } else {
        this.items.push(this.filteredItems[this.hoveredIndex]);
      }
      if (this.customKey) {
        this.$emit(
          'input',
          this.items.map(item => item[this.customKey])
        );
      } else {
        this.$emit('input', this.items);
      }
    },
    keyArrows(direction) {
      const sum = direction === 'down' ? 1 : -1;
      let index = this.hoveredIndex + sum;
      index =
        index > this.filteredItems.length - 1
          ? this.filteredItems.length
          : index;
      index = index < 0 ? 0 : index;

      this.setHovered(index);
    },
    removeItem(index) {
      this.items.splice(index, 1);
      if (this.customKey) {
        this.$emit(
          'input',
          this.items.map(item => item[this.customKey])
        );
      } else {
        this.$emit('input', this.items);
      }
    },
    selectAll() {
      let selectedList = this.filteredItems.filter(filteredItem => {
        return !this.items.find(
          item => filteredItem[this.selectedKey] === item[this.selectedKey]
        );
      });
      this.items.push(...selectedList);
      if (this.customKey) {
        this.$emit(
          'input',
          this.items.map(item => item[this.customKey])
        );
      } else {
        this.$emit('input', this.items);
      }
    },
    deselectAll() {
      this.filteredItems.forEach(filteredItem => {
        let deselectItemIndex = this.items.findIndex(
          item => filteredItem[this.selectedKey] === item[this.selectedKey]
        );
        if (deselectItemIndex > -1) {
          this.items.splice(deselectItemIndex, 1);
        }
      });
      this.$emit('input', []);
    },
    setHovered(index) {
      this.hoveredIndex = index;
    },
    setSearchInputFocus(active) {
      if (active) {
        this.$nextTick(() => this.$refs.input.$refs.input.focus());
        this.setHovered(0);
      } else {
        if (this.isUpdated) {
          this.$emit('done', this.items);
          this.isUpdated = false;
        }
      }
    },
    setData(list) {
      if (this.dataFilter) {
        this.itemList = this.dataFilter(list);
      } else {
        this.itemList = list;
      }

      this.itemSearchBox = new fuse(this.itemList, {
        threshold: 0,
        location: 0,
        distance: 10,
        tokenize: true,
        matchAllTokens: true,
        maxPatternLength: 16,
        minMatchCharLength: 3,
        keys: [this.field]
      });

      if (this.value && this.value.length) {
        const valueList = this.value.map(i => Number(i));
        this.items = this.itemList.filter(item =>
          valueList.includes(item[this.selectedKey])
        );
      }
    },
    setValue(value) {
      if (this.customKey) {
        this.$emit(
          'input',
          value.map(item => item[this.customKey])
        );
      } else {
        this.$emit('input', value);
      }
      this.isUpdated = true;
    }
  }
};
</script>

<style lang="scss">
.nx-multi-select {
  &__bulk-actions {
    height: 1.5rem;
    width: 100%;
  }
  &__bulk-action {
    text-decoration: underline;
    margin-top: 0.5rem;
    float: right;
  }
  & .taginput .taginput-container > .tag,
  .taginput .taginput-container > .tags {
    margin-right: 0.275rem;
    margin-bottom: 0;
  }
  &.dropdown,
  & .dropdown-trigger,
  & .dropdown-menu {
    width: 100%;
    min-width: 12rem;
  }
  & .dropdown-menu {
    min-width: 16rem;
  }
  & .taginput .taginput-container {
    padding-bottom: calc(0.375em - 1px);
    padding-left: calc(0.625em - 1px);
    padding-right: calc(0.625em - 1px);
    padding-top: calc(0.375em - 1px);
  }
  & .dropdown-content {
    overflow-y: scroll;
    max-height: 25rem;

    & .dropdown-item {
      outline: none;
    }
  }
  & .buttons .button {
    flex-grow: 1;
    flex-basis: 0;
  }
  & .dropdown-item,
  & .dropdown .dropdown-menu .has-link a,
  & .dropdown .dropdown-menu .has-link a {
    white-space: normal;
  }
}
</style>
