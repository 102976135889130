import Vue from 'vue';
import Vuex from 'vuex';
import account from './account';
import balanceMovement from './balanceMovement';
import country from './country';
import currency from './currency';
import customer from './customer';
import group from './group';
import me from './me';
import module from './module';
import operator from './operator';
import oracleCustomer from './oracleCustomer';
import permission from './permission';
import product from './product';
import product_type from './product_type';
import provider from './provider';
import retailer from './retailer';
import region from './region';
import service from './service';
import setting from './setting';
import smsBlacklist from './smsBlacklist';
import user from './user';
import productTag from './productTag';

const autoFetchResult = store => {
  store.subscribe((mutationEvent, state) => {
    const [namespace, mutation] = mutationEvent.type.split('/');
    if (mutation) {
      if (hasApiQueryChanged(mutation)) {
        // TODO: change hardcoded action call
        store.dispatch(`${namespace}/getList`);
      }
    }
  });
};

function hasApiQueryChanged(mutation) {
  const mutationList = ['setFilter', 'setPage', 'setSort', 'setInclude'];
  return mutationList.includes(mutation);
}

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    accountItem: account.item,
    accountList: account.list,
    balanceMovementItem: balanceMovement.item,
    balanceMovementList: balanceMovement.list,
    customerItem: customer.item,
    customerList: customer.list,
    smsBlacklistItem: smsBlacklist.item,
    smsBlacklistList: smsBlacklist.list,
    groupItem: group.item,
    groupList: group.list,
    permissionItem: permission.item,
    permissionList: permission.list,
    productItem: product.item,
    productList: product.list,
    userItem: user.item,
    userList: user.list,
    me,
    setting,
    retailer,
    oracleCustomer,
    operator,
    country,
    module,
    provider,
    service,
    product_type,
    region,
    currency,
    productTag
  },
  plugins: [autoFetchResult]
});
