import { difference, equals } from 'ramda';
import { saveAs } from 'file-saver';
import { parse, unparse } from 'papaparse';

const pick = (Obj, ...Keys) => Keys.reduce((o, k) => ((o[k] = Obj[k]), o), {});

const diff = (newItem, oldItem) => {
  const changes = [];
  Object.keys(newItem).forEach(key => {
    if (newItem[key] !== oldItem[key]) {
      changes.push({
        property: key,
        old: oldItem[key],
        new: newItem[key]
      });
    }
  });
  return changes;
};

const objDiff = (oldItem, newItem) => {
  const changes = {};
  Object.keys(newItem).forEach(key => {
    if (!equals(newItem[key], oldItem[key])) {
      changes[key] = newItem[key];
    }
  });
  return changes;
};

const isChanged = function (a, b) {
  return difference([a], [b]).length == 0 ? false : true;
};

const accessNestedObj = (Obj, ...Keys) =>
  Keys.reduce((acc, cur) => acc[cur], Obj);

function scrollUp(element) {
  const offset =
    element.children[1].children[0].children[0] &&
    element.children[1].children[0].children[0].offsetTop;
  element.children[1].children[0].scrollTop = offset;
}

const round = (num, decimals) => {
  let multiplier = 10 ** decimals;
  return Math.round((Number(num) + Number.EPSILON) * multiplier) / multiplier;
};

function saveCSV(name, data) {
  const content = unparse(data, {
    quotes: false, //or array of booleans
    quoteChar: '"',
    escapeChar: '"',
    delimiter: ',',
    header: true,
    newline: '\r\n',
    skipEmptyLines: false, //other option is 'greedy', meaning skip delimiters, quotes, and whitespace.
    columns: null //or array of strings
  });
  const blob = new Blob([content], { type: 'text/plain;charset=utf-8' });
  saveAs(blob, name);
}

function readCSV(file) {
  const result = parse(file, {
    delimiter: '', // auto-detect
    newline: '', // auto-detect
    quoteChar: '"',
    escapeChar: '"',
    header: true,
    transformHeader: undefined,
    dynamicTyping: true,
    preview: 0,
    encoding: '',
    worker: false,
    comments: false,
    step: undefined,
    complete: undefined,
    error: undefined,
    download: false,
    downloadRequestHeaders: undefined,
    downloadRequestBody: undefined,
    skipEmptyLines: false,
    chunk: undefined,
    chunkSize: undefined,
    fastMode: undefined,
    beforeFirstChunk: undefined,
    withCredentials: undefined,
    transform: undefined,
    delimitersToGuess: [',', '\t', '|', ';']
  });

  return result.data;
}

function sanitizeText(value) {
  value = value.replace(/\t/g, '');
  return value.trim();
}

function getHiddenColumns(defaultHiddenColumns) {
  if (
    localStorage.preferences &&
    JSON.parse(localStorage.preferences).pricelist &&
    JSON.parse(localStorage.preferences).pricelist.hiddenColumns
  ) {
    const hiddenColumns = JSON.parse(localStorage.preferences).pricelist
      .hiddenColumns;
    return hiddenColumns;
  } else {
    return defaultHiddenColumns;
  }
}

export {
  pick,
  diff,
  isChanged,
  accessNestedObj,
  scrollUp,
  round,
  objDiff,
  saveCSV,
  readCSV,
  sanitizeText,
  getHiddenColumns
};
