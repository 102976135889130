import constructStore from './base';

const listState = {
  filters: { 'type.id:-in': [8, 9, 14, 15] },
  orfilters: {},
  sort: ['-me.id'],
  include: ['balance.account.customer', 'type', 'created_by'],
  page: {
    number: 1,
    size: 20
  },
  count: 0,
  balance_movements: []
};

const listStore = constructStore(
  listState,
  'balance_movements',
  'balance_movements'
);

listStore.getters.getBalanceMovements = state => {
  return state.balance_movements;
};

const itemState = {
  include: [],
  balance_movement: {}
};

const itemStore = constructStore(
  itemState,
  'balance_movement',
  'balance_movements'
);

export default {
  list: listStore,
  item: itemStore
};
