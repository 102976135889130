export default {
  namespaced: true,
  state: {
    default: {
      pageSize: 50
    }
  },
  getters: {},
  mutations: {},
  actions: {}
};
