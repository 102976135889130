import constructStore from './base';

const listState = {
  filters: {},
  orfilters: {},
  sort: ['-me.id'],
  include: [
    'customer_account_managers.account_manager',
    'customer_contacts',
    'customer_logins.customer_login_accounts.account',
    'ip_whitelists'
  ],
  page: {
    number: 1,
    size: 20
  },
  count: 0,
  customers: []
};

const listStore = constructStore(listState, 'customers', 'customers');

listStore.getters.getCustomers = state => {
  return state.customers;
};

const itemState = {
  include: ['customer_account_managers.account_manager'],
  sort: [],
  customer: {}
};

const itemStore = constructStore(itemState, 'customer', 'customers');

export default {
  list: listStore,
  item: itemStore
};
