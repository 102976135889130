import Vue from 'vue';
import numeral from 'numeral';
import { format, parseISO, formatDistanceToNow } from 'date-fns';
import { utcToZonedTime } from 'date-fns-tz';
import { round } from '@/common/helpers';

const MAX_DECIMAL_PLACES = 6;

Vue.filter('currency', (value, decimals) => {
  // TODO: to replace with filter numeral below
  if ((!value && value !== 0) || isNaN(value)) return value;
  value = round(value, MAX_DECIMAL_PLACES);
  if (Number.isInteger(decimals)) {
    let zeroStr = new Array(decimals).fill(0).join('');
    return numeral(Number(value)).format(`0,0.${zeroStr}`);
  } else {
    return numeral(Number(value)).format('0,0.00[00]');
  }
});

Vue.filter('numeral', (value, { decimals, prefix, suffix } = {}) => {
  if ((!value && value !== 0) || isNaN(value)) return value;
  value = round(value, MAX_DECIMAL_PLACES);
  let num;
  if (Number.isInteger(decimals)) {
    let zeroStr = new Array(decimals).fill(0).join('');
    num = numeral(Number(value)).format(`0,0.${zeroStr}`);
  } else {
    num = numeral(Number(value)).format('0,0.00[00]');
  }
  num = [prefix, num, suffix].join('');
  return num;
});

Vue.filter('date', datestr => {
  if (!datestr) return null;
  return format(
    utcToZonedTime(
      parseISO(datestr).toISOString(),
      process.env.VUE_APP_TIMEZONE
    ),
    'yyyy/MM/dd HH:mm'
  );
});

Vue.filter('summary', (value, targetLength) => {
  let newValue;
  try {
    let x = value.slice(0, targetLength).length > targetLength - 1 ? '...' : '';
    newValue = value.slice(0, targetLength) + x;
  } catch (error) {
    newValue = value;
  }
  return newValue;
});

Vue.filter('prefix', value => {
  if ((!value && value !== 0) || isNaN(value)) return value;
  const regex = /^\+/;
  if (!value.match(regex)) {
    return `+${value}`;
  }
  return value;
});

Vue.filter('humanDate', datestr => {
  if (!datestr) return null;
  let isoDateStr = parseISO(datestr);
  return formatDistanceToNow(isoDateStr);
});
