import { render, staticRenderFns } from "./NxToggle.vue?vue&type=template&id=2227038c&scoped=true&"
import script from "./NxToggle.vue?vue&type=script&lang=js&"
export * from "./NxToggle.vue?vue&type=script&lang=js&"
import style0 from "./NxToggle.vue?vue&type=style&index=0&id=2227038c&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2227038c",
  null
  
)

export default component.exports