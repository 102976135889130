<template>
  <div id="app">
    <div id="nav">
      <NavBar />
    </div>
    <div>
      <transition name="slide-fade">
        <router-view />
      </transition>
    </div>
  </div>
</template>

<script>
import NavBar from '@/components/NavBar.vue';

export default {
  name: 'Home',
  components: {
    NavBar
  }
};
</script>

<style lang="scss">
@import '@/common/style.scss';
</style>
