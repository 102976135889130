import init from './init';

const serviceStore = init('services');

export default Object.assign(serviceStore, {
  state: {
    _items: [],
    _query: {
      filters: {
        'me.active:eq': true
      },
      sort: ['name']
    }
  }
});
