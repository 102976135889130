import Vue from 'vue';
import NxAutocomplete from '@/components/NxAutocomplete';
import QuickView from '@/components/QuickView';
import NxMultiSelect from '@/components/NxMultiSelect';
import NxHoverActionBar from '@/components/NxHoverActionBar';
import NxSimpleAutocomplete from '@/components/NxSimpleAutocomplete';
import NxDebouncedInput from '@/components/NxDebouncedInput';
import NxToggle from '@/components/NxToggle';
import SubNavBar from '@/components/SubNavBar';
import RevisionHistory from '@/components/RevisionHistory';

Vue.component('NxAutocomplete', NxAutocomplete);
Vue.component('QuickView', QuickView);
Vue.component('NxMultiSelect', NxMultiSelect);
Vue.component('NxHoverActionBar', NxHoverActionBar);
Vue.component('NxSimpleAutocomplete', NxSimpleAutocomplete);
Vue.component('NxDebouncedInput', NxDebouncedInput);
Vue.component('NxToggle', NxToggle);
Vue.component('SubNavBar', SubNavBar);
Vue.component('RevisionHistory', RevisionHistory);
