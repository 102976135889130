import { get } from '@/common/api';
export default {
  namespaced: true,
  state: {
    info: {}
  },
  getters: {},
  mutations: {
    setItem(state, data) {
      state.info = data;
    }
  },
  actions: {
    getItem({ commit, state }) {
      return new Promise((resolve, reject) => {
        get('me').then(response => {
          commit('setItem', response.data);
          resolve(state);
        });
      });
    }
  }
};
