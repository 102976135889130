import Vue from 'vue';

Vue.directive('word-break', (el, binding) => {
  let formatString = binding.value;
  const modifiers = binding.modifiers;
  if (formatString && Object.entries(modifiers).length) {
    if (modifiers.underscore) {
      formatString = formatString.replace(/_/g, '$&<wbr />');
    }
    if (modifiers['double-colon']) {
      formatString = formatString.replace(/::/g, '$&<wbr />');
    }
    if (modifiers['dot']) {
      formatString = formatString.replace(/\./g, '$&<wbr />');
    }
    if (modifiers['comma']) {
      formatString = formatString.replace(/,/g, '$&<wbr />');
    }
    if (modifiers['capital-letter']) {
      formatString = formatString.replace(/[A-Z]+[^A-Z]*/g, '<wbr />$&');
    }
    el.innerHTML = formatString;
  } else {
    el.innerHTML = '';
  }
});
