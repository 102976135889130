import init from './init';

const productTypeStore = init('product_types');

export default Object.assign(productTypeStore, {
  state: {
    _items: [],
    _query: {
      filters: {
        'me.active:eq': true
      },
      sort: ['name']
    }
  }
});
