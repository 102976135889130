<template>
  <div>
    <aside
      class="quickview-left-side"
      :class="{ 'quickview-left-side-active': show }"
      @click="$emit('hide')"
    ></aside>
    <aside
      class="quickview message is-dark"
      :class="{ 'quickview-active': show }"
    >
      <header class="quickview-header message-header">
        <span class="delete is-large" @click="$emit('hide')"></span>
        <p class="title">
          <slot name="title"></slot>
        </p>
      </header>
      <div class="quickview-body message-body">
        <slot name="body"></slot>
      </div>
    </aside>
  </div>
</template>

<script>
export default {
  name: 'QuickView',
  props: ['show']
};
</script>

<style lang="scss">
.quickview {
  position: fixed;
  z-index: 1000;
  top: 0;
  right: -60vw;
  width: 60vw;
  height: 100vh;
  background-color: white;
  transition: right linear 0.2s;
  overflow-y: scroll;
}

.quickview-header {
  border-bottom: 1px solid lightgrey;
  border-radius: 0rem;
  position: sticky;
  top: 0;
}
.quickview-header .title {
  font-size: 1em;
  color: $white;
  margin-bottom: 0;
}

.quickview-active {
  right: 0;
  box-shadow: 5px 0px 13px 3px rgba(0, 0, 0, 0.1);
  transition: right linear 0.2s;
}

.quickview-body thead th {
  position: sticky;
  top: calc(0.75em * 2 + 32px);
  background-color: white;
}

.quickview-left-side {
  position: fixed;
  z-index: 1000;
  top: 0;
  right: -60vw;
  width: 40vw;
  height: 100vh;
  background-color: white;
  opacity: 0.6;
}

.quickview-left-side-active {
  right: 60vw;
}
</style>
