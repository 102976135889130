import { get } from '@/common/api';

export default {
  namespaced: true,
  state: {
    _items: [],
    _query: {
      filters: {
        'me.is_active:eq': true
      },
      sort: ['name']
    }
  },
  getters: {
    items: state => state._items
  },
  mutations: {
    setList(state, data) {
      state._items = data;
    }
  },
  actions: {
    fetchList({ commit, state }) {
      get('providers', state._query)
        .then(response => {
          commit('setList', response.data.data);
        })
        .catch(console.log);
    }
  }
};
