<template>
  <nav class="navbar is-light" role="navigation" aria-label="main navigation">
    <div class="navbar-brand">
      <a class="navbar-item" href="#/dashboard">
        <img id="brand-image" :src="logo" alt="Nexus Admin" />
      </a>
      <a
        ref="navbarBurger"
        role="button"
        class="navbar-burger burger"
        aria-label="menu"
        aria-expanded="false"
        @click="toggleMobileMenu"
      >
        <span aria-hidden="true" />
        <span aria-hidden="true" />
        <span aria-hidden="true" />
      </a>
    </div>
    <div ref="navbarMenu" class="navbar-menu" @click="toggleMobileMenu">
      <div class="navbar-start">
        <div class="navbar-item has-dropdown is-hoverable">
          <a class="navbar-item navbar-link">Configuration</a>
          <div class="navbar-dropdown">
            <router-link
              v-if="$canRead('products')"
              class="navbar-item"
              to="/products"
              >Products</router-link
            >
            <router-link
              v-if="$canRead('routes')"
              class="navbar-item nx-nav-tag"
              to="/routes"
              ><span>Routes</span>
            </router-link>
            <router-link
              v-if="$canRead('modules')"
              class="navbar-item nx-nav-tag"
              to="/modules"
              ><span>Modules</span>
            </router-link>
            <router-link
              v-if="$canRead('promotions')"
              class="navbar-item"
              to="/promotions"
              >Operators Promotions</router-link
            >
            <hr class="dropdown-divider" />
            <router-link class="navbar-item" to="/operators"
              >Operators</router-link
            >
            <router-link
              v-if="$canRead('providers')"
              class="navbar-item"
              to="/providers"
              >Providers</router-link
            >
            <router-link class="navbar-item nx-nav-tag" to="/countries"
              >Countries
            </router-link>
            <router-link class="navbar-item nx-nav-tag" to="/countries_banks"
              >Countries and Banks
            </router-link>
            <router-link class="navbar-item nx-nav-tag" to="/currencies"
              >Currencies
            </router-link>
            <hr class="dropdown-divider" />
            <router-link
              v-if="$canRead('costing')"
              class="navbar-item nx-nav-tag"
              to="/costs"
              >Costs
            </router-link>
            <router-link
              v-if="$canRead('costing')"
              class="navbar-item nx-nav-tag"
              to="/costing_rules"
              >Costing Rules
            </router-link>
            <hr class="dropdown-divider" />
            <router-link class="navbar-item" to="/product_segments"
              >Product Segments
            </router-link>
          </div>
        </div>
        <div
          v-if="
            $canRead('customers') ||
            $canRead('accounts') ||
            $canRead('balance_movements') ||
            $canRead('pricelists')
          "
          class="navbar-item has-dropdown is-hoverable"
        >
          <a class="navbar-item navbar-link">Manage Customers</a>
          <div class="navbar-dropdown">
            <router-link
              v-if="$canRead('customers')"
              class="navbar-item"
              to="/customers"
              >Customers</router-link
            >
            <router-link
              v-if="$canRead('accounts')"
              class="navbar-item"
              to="/accounts"
              >Accounts</router-link
            >
            <router-link
              v-if="$canRead('balance_movements')"
              class="navbar-item"
              to="/balance_management"
              >Balance Management</router-link
            >
            <hr class="dropdown-divider" />
            <router-link
              v-if="$canRead('pricelists')"
              class="navbar-item"
              to="/pricelists"
              >Pricelists</router-link
            >
            <hr class="dropdown-divider" />
            <router-link
              v-if="$canRead('customers')"
              class="navbar-item"
              to="/terms_and_conditions"
              >Terms and Conditions</router-link
            >
          </div>
        </div>
        <router-link
          v-if="$canRead('transactions')"
          class="navbar-item"
          to="/transactions"
          >Transactions</router-link
        >
        <div
          v-if="$canRead('report')"
          class="navbar-item has-dropdown is-hoverable"
        >
          <a class="navbar-item navbar-link">Reports</a>
          <div class="navbar-dropdown">
            <router-link class="navbar-item" to="/reports/customer">
              Transaction - Customer
            </router-link>
            <router-link class="navbar-item" to="/reports/provider">
              Transaction - Provider
            </router-link>
          </div>
        </div>
        <div
          v-if="$canRead('groups') || $canWrite('users')"
          class="navbar-item has-dropdown is-hoverable"
        >
          <a class="navbar-item navbar-link">Admin</a>
          <div class="navbar-dropdown">
            <router-link
              v-if="$canRead('users')"
              class="navbar-item"
              to="/logins"
              >Logins</router-link
            >
            <router-link
              v-if="$canRead('groups')"
              class="navbar-item"
              to="/groups"
              >Groups</router-link
            >
            <router-link
              v-if="$canWrite('users')"
              class="navbar-item"
              to="/users"
              >Users</router-link
            >
            <router-link
              v-if="$canRead('permissions')"
              class="navbar-item"
              to="/permissions"
              >Permissions</router-link
            >
          </div>
        </div>
      </div>

      <div class="navbar-end">
        <div
          v-if="$canRead('notifications')"
          class="navbar-item has-dropdown is-hoverable"
        >
          <notification></notification>
        </div>
        <div class="navbar-item">
          <span class="tag" :class="environmentClass">
            {{ environment }} | ({{ $timeZone }})
          </span>
        </div>
        <div class="navbar-item has-dropdown is-hoverable">
          <div class="navbar-link">
            {{ profile.firstname }} {{ profile.lastname }}
          </div>
          <div class="navbar-dropdown">
            <a class="navbar-item"> Profile </a>
            <router-link
              v-if="$canWrite('activity_history')"
              class="navbar-item"
              to="/activity_history"
              >Activity History</router-link
            >
            <a class="navbar-item" @click="signout()"> Sign Out </a>
          </div>
        </div>
      </div>
    </div>
  </nav>
</template>

<script>
import { removeToken } from '@/common/auth';
import Notification from './Notification.vue';
export default {
  name: 'NavBar',
  components: {
    Notification
  },
  data() {
    return {
      environment: process.env.VUE_APP_ENV,
      environmentClass: process.env.VUE_APP_ENV_CLASS,
      logo: process.env.VUE_APP_LOGO
    };
  },
  computed: {
    profile() {
      return this.$store.state.me.info;
    }
  },
  methods: {
    signout() {
      removeToken();
      window.location.replace(`${process.env.VUE_APP_API}/auth/login`);
    },
    toggleMobileMenu(ev) {
      this.$refs.navbarBurger.classList.toggle('is-active');
      this.$refs.navbarMenu.classList.toggle('is-active');
    }
  }
};
</script>

<style scoped lang="scss">
#brand-image {
  max-height: 2rem;
}
.nx-nav-tag {
  display: flex;
  justify-content: space-between;
  padding-right: 1rem !important;
  & .tag {
    margin-left: 1rem;
  }
}

.nested.dropdown {
  display: block;
}

.nested.dropdown:hover > .dropdown-menu {
  display: block;
}
.nested.dropdown .dropdown-menu {
  top: -10px;
  margin-left: 100%;
}
.nested.dropdown .dropdown-trigger button {
  padding: 0px 0px;
  border: 0px;
  font-size: 14px;
  font-weight: 400;
}
</style>
